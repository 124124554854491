import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import {
  PropertyStateArgs,
  PropertyStateFields,
  PropertyStateSources,
} from '@hcs/types';
import { DataVerification } from '@hcs/types';

import {
  TwoColTable,
  TwoColTableDataItem,
  TwoColTableProps,
} from '../TwoColTable';

import styles from './PropertyCompareTable.module.css';

type SourceType = PropertyStateSources | 'appraisal';
export interface PropertyCompareTableProps {
  className?: string;
  dataHcName: string;
  fields: PropertyStateFields[];
  theme?: TwoColTableProps['theme'];
  dataVerificationConfig?: {
    primarySource: SourceType;
    compareSource: SourceType;
    dataVerification: DataVerification;
  };
  properties: {
    uniqueId: string | number;
    label: ReactNode;
    source?: PropertyStateSources | 'appraisal';
    propertyStateArgs: PropertyStateArgs;
  }[];
}

interface UserPropertyAdjustedBadgeProps {
  dataHcName: string;
}

export const UserPropertyAdjustedBadge = ({
  dataHcName,
}: UserPropertyAdjustedBadgeProps) => {
  return (
    <div
      className={styles.UserPropertyAdjustedBadge}
      data-hc-name={`${dataHcName}-user-property-adjusted-badge`}
    >
      Adjusted
    </div>
  );
};
const dataHcName = 'property-compare-table';
export const PropertyCompareTable = ({
  fields,
  className,
  theme,
  properties,
  dataVerificationConfig,
}: PropertyCompareTableProps) => {
  const propertyColStyle = { flex: `0 0 ${100 / properties.length}%` };
  const data = [
    {
      dataHcName: `${dataHcName}-labels`,
      label: '',
      value: (
        <div className={styles.ValueRow}>
          {properties.map((property) => {
            return (
              <div
                key={property.uniqueId}
                className={styles.PropertyContainer}
                style={propertyColStyle}
              >
                <span
                  className={classNames(
                    styles.DisplayContainer,
                    styles.PropertyLabel,
                  )}
                >
                  {property.label}
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    ...fields.map((field): TwoColTableDataItem => {
      const { labelShort, Display } = PROPERTY_STATE_FIELD_CONFIGS[field];
      const fieldPrimary =
        dataVerificationConfig &&
        dataVerificationConfig.dataVerification?.[field]?.[
          dataVerificationConfig.primarySource
        ];
      const fieldCompare =
        dataVerificationConfig &&
        dataVerificationConfig.dataVerification?.[field]?.[
          dataVerificationConfig.compareSource
        ];
      const highlightDiscrepancy = fieldPrimary !== fieldCompare;
      return {
        dataHcName: dataHcName,
        label: labelShort,
        classNameValue: classNames({
          [styles.highlightDiscrepancy]: highlightDiscrepancy,
        }),
        value: (
          <div className={styles.ValueRow}>
            {properties.map((property) => {
              return (
                <div
                  key={property.uniqueId}
                  className={styles.PropertyContainer}
                  style={propertyColStyle}
                >
                  <span className={styles.DisplayContainer}>
                    <Display propertyStateArgs={property.propertyStateArgs} />
                  </span>
                  {/* {fieldUserAdjustment && (
                  <span className={styles.BadgeContainer}>
                    <UserPropertyAdjustedBadge
                      dataHcName={`${dataHcName}-adjustment-field`}
                    />
                  </span>
                  )} */}
                </div>
              );
            })}
          </div>
        ),
      };
    }),
  ];
  return (
    <TwoColTable
      smallLabels
      className={className}
      dataHcName={dataHcName}
      data={data}
      theme={theme}
    />
  );
};
