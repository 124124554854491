import { DocumentRoles, InspectionPhotosDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useInspectionPhotosDocument = <
  Data extends ReportApiReports,
>() => {
  return useSingleDocumentRole<InspectionPhotosDocument, Data>(
    DocumentRoles.InspectionPhotos,
  );
};
