import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-location-section';

export const ListingLocationSection = () => {
  const { propertyStateCore } = useMlsListingSheetData();
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'County & Location Information' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          PropertyStateFields.apn,
          PropertyStateFields.zoning,
          {
            dataHcName: 'county-fips',
            label: 'County FIPS',
            value: formatMissing(
              'Old Path: listing.legacyData?.census?.countyFips',
            ),
          },
          {
            dataHcName: 'school-district',
            label: 'School District',
            value: formatMissing(
              'Old Path: listing.legacyData?.census?.school?.unifiedDistrictName',
            ),
          },
          {
            dataHcName: 'place-name',
            label: 'Place Name',
            value: formatMissing(
              'Old Path: listing.legacyData?.census?.placeName',
            ),
          },
        ].map((field) => {
          if (typeof field === 'string') {
            const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return {
              dataHcName: `${dataHcName}-${field}`,
              value: <Display propertyStateArgs={propertyStateCore} />,
              label,
            };
          } else {
            return field;
          }
        })}
      />
    </Section>
  );
};
