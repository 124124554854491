import { GlossaryTerms } from '@hcs/types';
import { ValuationMethod } from '@hcs/types';

const GLOSSARY_TERMS_BY_VALUATION_METHOD = {
  [ValuationMethod.Avm]: GlossaryTerms.HcValue,
  [ValuationMethod.Adjusted]: GlossaryTerms.AdjustedValue,
  [ValuationMethod.Comps]: GlossaryTerms.ComparableValueSelected,
  [ValuationMethod.UserEntered]: GlossaryTerms.UserOpinionOfPrice,
};

const GLOSSARY_TERMS_BY_VALUATION_METHOD_RENTAL = {
  [ValuationMethod.Avm]: GlossaryTerms.RentalHcValue,
  [ValuationMethod.Adjusted]: undefined,
  [ValuationMethod.Comps]: GlossaryTerms.RentalComparableValueSelected,
  [ValuationMethod.UserEntered]: GlossaryTerms.RentalUserOpinionOfPrice,
};

export const getGlossaryTermByValuationMethod = (
  valuationMethod: ValuationMethod,
  showRentalValue?: boolean,
) => {
  if (showRentalValue) {
    return GLOSSARY_TERMS_BY_VALUATION_METHOD_RENTAL[valuationMethod];
  } else {
    return GLOSSARY_TERMS_BY_VALUATION_METHOD[valuationMethod];
  }
};
