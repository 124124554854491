import React from 'react';
import classNames from 'classnames';

import {
  SimilarityLevelLabel,
  SimilarityLevelLabelProps,
} from '@hcs/pdf/pdf-service';
import { SubjectMarker } from '@hcs/pdf/pdf-service';
import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import { CompFields, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompSchema, SubjectSchema } from '@hcs/types';
import {
  formatCityStateZip,
  formatStreetAddress,
  NULL_VALUE,
} from '@hcs/utils';

import { isCompField } from '../../utils/comps.utils';

import styles from './CompsTable.module.css';

interface Props {
  dataHcName: string;
  subjectSchema: SubjectSchema;
  rows: { className?: string; compSchema: CompSchema }[];
  fields: (CompFields | PropertyStateFields)[];
  highlight?: SimilarityLevelLabelProps['highlight'];
}

const CompsTableCell = ({
  dataHcName,
  field,
  schema,
  isSubject,
}:
  | {
      dataHcName: string;
      field: CompFields | PropertyStateFields;
      schema: CompSchema;
      isSubject?: false;
    }
  | {
      dataHcName: string;
      field: CompFields | PropertyStateFields;
      schema: SubjectSchema;
      isSubject: true;
    }) => {
  if (isCompField(field)) {
    const { Display } = COMP_FIELDS_CONFIG[field];
    return (
      <td
        key={`cell-${field}`}
        data-hc-name={dataHcName}
        className={classNames(
          styles.Td,
          // TODO: Align styles
          styles.left,
        )}
      >
        {isSubject ? NULL_VALUE : <Display comp={schema} />}
      </td>
    );
  } else {
    const { Display } = PROPERTY_STATE_FIELD_CONFIGS[field];
    return (
      <td
        key={`cell-${field}`}
        className={classNames(
          styles.Td,
          // TODO: Align styles
          styles.left,
        )}
      >
        <Display
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: schema.propertyState,
          }}
        />
      </td>
    );
  }
};
export const CompsTable = ({
  dataHcName,
  subjectSchema,
  rows,
  highlight,
  fields,
}: Props) => {
  return (
    <table data-hc-name={dataHcName} className={styles.Table}>
      <tbody>
        <tr
          data-hc-name={`${dataHcName}-header`}
          className={classNames(styles.Tr, styles.Header)}
        >
          <th className={styles.Th}>#</th>
          <th className={styles.Th}>Address</th>
          {fields.map((field) => {
            const { labelShort } = isCompField(field)
              ? COMP_FIELDS_CONFIG[field]
              : PROPERTY_STATE_FIELD_CONFIGS[field];
            return (
              <th
                key={`head-${field}`}
                data-hc-name={`${dataHcName}-header-cell-${field}`}
                className={classNames(
                  styles.Th,
                  // TODO Align styles
                  styles.left,
                )}
              >
                {labelShort}
              </th>
            );
          })}
        </tr>
        <tr className={styles.Tr}>
          <td className={styles.Td}>
            <SubjectMarker />
          </td>
          <td className={styles.Td}>
            <div data-hc-name={`${dataHcName}-subject-street-address`}>
              {formatStreetAddress(subjectSchema.propertyState.location)}
            </div>
          </td>
          {fields.map((field) => (
            <CompsTableCell
              key={`cell-subject-${field}`}
              dataHcName={`${dataHcName}-subject-${field}`}
              field={field}
              schema={subjectSchema}
              isSubject
            />
          ))}
        </tr>
        {rows.map(({ compSchema, className: rowClassName }, idx) => {
          return (
            <tr key={idx} className={classNames(styles.Tr, rowClassName)}>
              <td className={styles.Td}>
                <SimilarityLevelLabel
                  highlight={highlight}
                  dataHcName={`${dataHcName}-sim-label-${idx + 1}`}
                  label={idx + 1}
                  similarityLevel={compSchema.similarity.levelAdjusted}
                />
              </td>
              <td className={styles.Td}>
                <div data-hc-name={`${dataHcName}-comp-street-address`}>
                  {formatStreetAddress(compSchema.propertyState.location)}
                </div>
                <div data-hc-name={`${dataHcName}-comp-city-state-zip`}>
                  {formatCityStateZip(compSchema.propertyState.location)}
                </div>
              </td>
              {fields.map((field) => (
                <CompsTableCell
                  key={`cell-${
                    compSchema.compID || compSchema.propertyState.hcAddressId
                  }-${field}`}
                  dataHcName={`${dataHcName}-comp-${field}`}
                  field={field}
                  schema={compSchema}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
