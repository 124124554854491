import {
  DocumentRoles,
  ReportApiReports,
  SaleToListPriceRatioChartDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSaleToListPriceRatioChartDocument = <
  Data extends ReportApiReports,
>() => {
  return useSingleDocumentRole<SaleToListPriceRatioChartDocument, Data>(
    DocumentRoles.SaleToListPriceRatioChart,
  );
};
