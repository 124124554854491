import React from 'react';

import { formatMissing } from '@hcs/utils';

import { useAppraisalInputDocument } from '../..//hooks/useAppraisalInputDocument';

import styles from './AppraisalInputsInfo.module.css';

const dataHcName = 'apprailal-inputs-info';
export const AppraisalInputsInfo = () => {
  const appraisalInputDoc = useAppraisalInputDocument();
  return (
    <div data-hc-name={dataHcName} className={styles.AppraisalInfo}>
      <div>
        <div className={styles.Label}>Appraiser</div>
        <div className={styles.Value}>
          {formatMissing(appraisalInputDoc?.data.Appraiser.Name)}
        </div>
        <div className={styles.Value}>
          License #:
          {` ${formatMissing(
            appraisalInputDoc?.data.Appraiser.License.Identifier,
          )}, ${formatMissing(
            appraisalInputDoc?.data.Appraiser.License.State,
          )}`}
        </div>
      </div>
      <div>
        <div className={styles.Label}>AMC</div>
        <div className={styles.Value}>
          {formatMissing(appraisalInputDoc?.data.Appraiser.Company)}
        </div>
      </div>
      <div>
        <div className={styles.Label}>Lender</div>
        <div className={styles.Value}>
          {formatMissing(appraisalInputDoc?.data.LenderName)}
        </div>
      </div>
    </div>
  );
};
