import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatList } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-construction-section';

export const ListingConstructionSection = () => {
  const { reso, propertyStateCore } = useMlsListingSheetData();
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Construction Details' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          {
            dataHcName: 'style',
            label: 'Style',
            value: formatList(reso?.propertyDetails?.architecturalStyle),
          },
          PropertyStateFields.yearBuilt,
          {
            dataHcName: 'foundation',
            label: 'Foundation',
            value: formatList(reso?.propertyDetails?.foundationDetails),
          },
          {
            dataHcName: 'construction-materials',
            label: 'Construction Materials',
            value: formatList(reso?.propertyDetails?.constructionMaterials),
          },
          {
            dataHcName: 'attachment-type',
            label: 'Attachment Type',
            value:
              reso?.propertyDetails?.propertyAttachedYN === true
                ? 'Attached'
                : reso?.propertyDetails?.propertyAttachedYN === false
                  ? 'Detached'
                  : 'Unknown',
          },
          PropertyStateFields.stories,
        ].map((field) => {
          if (typeof field === 'string') {
            const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return {
              dataHcName: `${dataHcName}-${field}`,
              value: <Display propertyStateArgs={propertyStateCore} />,
              label,
            };
          } else {
            return field;
          }
        })}
      />
    </Section>
  );
};
