import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { ImgFit } from '@hcs/pdf/pdf-service';
import { NoImagePlaceHolder } from '@hcs/pdf/pdf-service';
import { UserPropertyAdjustedBadge } from '@hcs/pdf/pdf-service';
import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import {
  CompFields,
  CompTypes,
  PropertyStateFields,
  PropertyStateType,
  ReportTypes,
} from '@hcs/types';
import { CompSchema, SubjectSchema } from '@hcs/types';
import { UserPropertyAdjustments } from '@hcs/types';
import { CompIdToPhotoUrlMapping } from '@hcs/types';
import {
  formatCityStateZip,
  formatStreetAddress,
  MULTI_FIELD_DELIMITER,
  NULL_VALUE,
} from '@hcs/utils';

import { useReportConfig } from '../../hooks';
import { isCompField } from '../../utils/comps.utils';

import styles from './CompsCompareTable.module.css';

export interface CompsCompareSubject {
  type: 'subject';
  schema: SubjectSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}
export interface CompsCompareComp {
  type: 'comp';
  schema: CompSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}
export const MAX_COMPS_PER_PAGE_COMPARE = 4;
export interface CompsCompareTableProps {
  dataHcName: string;
  subject: CompsCompareSubject;
  comps: CompsCompareComp[];
  compType: CompTypes;
  fields: (
    | PropertyStateFields
    | CompFields
    | PropertyStateFields[]
    | CompFields[]
  )[];
  // Used to properly label comp lists that span multiple pages
  pageIndex?: number;
  subjectPhotoUrl?: string;
  compPhotoUrls?: CompIdToPhotoUrlMapping;
}

const CompsTableCell = ({
  dataHcName,
  isSubject,
  children,
  className,
}: {
  className?: string;
  dataHcName: string;
  isSubject?: boolean;
  children: ReactNode;
}) => {
  return (
    <td
      data-hc-name={dataHcName}
      className={classNames(styles.Td, className, {
        [styles.subject]: isSubject,
        [styles.comp]: !isSubject,
      })}
    >
      {children}
    </td>
  );
};

const AddressCell = ({
  dataHcName,
  schema,
  isSubject,
}: {
  dataHcName: string;
  schema: CompSchema | SubjectSchema;
  isSubject?: boolean;
}) => {
  return (
    <CompsTableCell
      className={styles.AddressCell}
      dataHcName={dataHcName}
      isSubject={isSubject}
    >
      <div
        className={styles.Address}
        data-hc-name={`${dataHcName}-street-address`}
      >
        {formatStreetAddress(schema.propertyState.location)}
      </div>
      <div
        className={styles.Address}
        data-hc-name={`${dataHcName}-city-state-zip`}
      >
        {formatCityStateZip(schema.propertyState.location)}
      </div>
    </CompsTableCell>
  );
};

interface PropsCompsFields {
  field: CompFields | CompFields[];
  compSchema: CompSchema;
}

const TableHeaderCompFields = ({
  field,
}: Omit<PropsCompsFields, 'compSchema'>) => {
  const fields = Array.isArray(field) ? field : [field];
  const labels: string[] = [];
  fields.forEach((compField) => {
    labels.push(COMP_FIELDS_CONFIG[compField].labelShort);
  });
  return <th className={styles.Th}>{labels.join(MULTI_FIELD_DELIMITER)}</th>;
};

const TableCellCompFields = ({ field, compSchema }: PropsCompsFields) => {
  const fields = Array.isArray(field) ? field : [field];
  return (
    <CompsTableCell
      dataHcName={`${compSchema.compID}-cell-${fields.join('-')}`}
    >
      {fields.map((compField, idx) => {
        const { Display } = COMP_FIELDS_CONFIG[compField];
        return (
          <Display
            comp={compSchema}
            className={classNames({
              [styles.Similarity]: compField === CompFields.similarity,
            })}
            key={`${compSchema.compID}-${idx}`}
          />
        );
      })}
    </CompsTableCell>
  );
};

interface PropsPropertyDetails {
  field: PropertyStateFields | PropertyStateFields[];
  schema: SubjectSchema | CompSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
  isSubject?: boolean;
}
const TableHeaderPropertyDetails = ({
  field,
}: {
  field: PropertyStateFields | PropertyStateFields[];
}) => {
  const fields = Array.isArray(field) ? field : [field];
  const labels: string[] = [];
  const isMultiFieldRow = fields.length > 1;
  const reportConfig = useReportConfig();
  const reportType = reportConfig.reportType;

  fields.forEach((propertyDetailsField) => {
    const { label, labelMicro, labelDynamic } =
      PROPERTY_STATE_FIELD_CONFIGS[propertyDetailsField];
    const shouldUseLabelDynamic =
      reportType === ReportTypes.EffectiveDate &&
      (propertyDetailsField === PropertyStateFields.currentValue ||
        propertyDetailsField === PropertyStateFields.currentValuePerSqFt);
    if (isMultiFieldRow && labelMicro) {
      labels.push(labelMicro);
    } else if (labelDynamic && reportType && shouldUseLabelDynamic) {
      labels.push(labelDynamic?.({ valueType: reportType }));
    } else {
      labels.push(label);
    }
  });
  return (
    <th data-hc-name={`header-${fields.join('-')}`} className={styles.Th}>
      {labels.join(MULTI_FIELD_DELIMITER)}
    </th>
  );
};

const TableCellPropertyDetails = ({
  field,
  schema,
  userPropertyAdjustments,
  isSubject,
}: PropsPropertyDetails) => {
  const fields = Array.isArray(field) ? field : [field];
  const isMultiField = fields.length > 1;
  const lastIdx = fields.length - 1;
  return (
    <CompsTableCell
      dataHcName={`${schema.propertyState.hcAddressId}-cell-${fields.join(
        '-',
      )}`}
      isSubject={isSubject}
    >
      <span className={styles.Field}>
        {fields.map((propertyDetailsField, idx) => {
          const { Display, formatValueShort } =
            PROPERTY_STATE_FIELD_CONFIGS[propertyDetailsField];
          const fieldUserAdjustment =
            userPropertyAdjustments?.adjustments?.[propertyDetailsField];
          return (
            <React.Fragment
              key={`${schema.propertyState.hcAddressId}-field-${field}-${idx}`}
            >
              <span className={styles.ColCellContent}>
                {isMultiField ? (
                  formatValueShort?.({
                    propertyStateType: PropertyStateType.Core,
                    propertyState: schema.propertyState,
                  })
                ) : (
                  <Display
                    propertyStateArgs={{
                      propertyStateType: PropertyStateType.Core,
                      propertyState: schema.propertyState,
                    }}
                    key={`${schema.propertyState.hcAddressId}-${idx}`}
                  />
                )}
                {fieldUserAdjustment && (
                  <UserPropertyAdjustedBadge
                    dataHcName={`${
                      schema.propertyState.hcAddressId
                    }-cell-adjustment-field${fields.join('-')}`}
                  />
                )}
                {idx !== lastIdx ? (
                  <span>&nbsp;{MULTI_FIELD_DELIMITER}&nbsp;</span>
                ) : null}
              </span>
            </React.Fragment>
          );
        })}
      </span>
    </CompsTableCell>
  );
};

const CompsTableRow = ({
  subjectSchema,
  comps,
  field,
}: {
  subjectSchema: SubjectSchema;
  comps: {
    type: 'comp';
    schema: CompSchema;
    userPropertyAdjustments?: UserPropertyAdjustments;
  }[];
  field:
    | PropertyStateFields
    | CompFields
    | PropertyStateFields[]
    | CompFields[];
}) => {
  // Grab a field to test what type of fields we are dealing with
  const testField = Array.isArray(field) ? field[0] : field;
  if (!testField) return null;
  return (
    <tr>
      {isCompField(testField) ? (
        // Can't figure out how to avoid casting but we can be confident this type is correct
        <TableHeaderCompFields field={field as CompFields | CompFields[]} />
      ) : (
        <TableHeaderPropertyDetails
          // Can't figure out how to avoid casting but we can be confident this type is correct
          field={field as PropertyStateFields | PropertyStateFields[]}
        />
      )}
      {isCompField(testField) ? (
        <CompsTableCell
          dataHcName={`subject-${
            Array.isArray(field) ? field.join('-') : field
          }`}
          isSubject
        >
          {NULL_VALUE}
        </CompsTableCell>
      ) : (
        <TableCellPropertyDetails
          key={`subject-${field}-cell`}
          schema={subjectSchema}
          // Can't figure out how to avoid casting but we can be confident this type is correct
          field={field as PropertyStateFields | PropertyStateFields[]}
          isSubject
        />
      )}
      {comps.map((comp) => {
        const keyStr = `${
          comp.schema.compID || comp.schema.propertyState.hcAddressId
        }-${field}-cell`;
        if (isCompField(testField)) {
          return (
            <TableCellCompFields
              key={keyStr}
              compSchema={comp.schema}
              // Can't figure out how to avoid casting but we can be confident this type is correct
              field={field as CompFields | CompFields[]}
            />
          );
        } else {
          return (
            <TableCellPropertyDetails
              key={keyStr}
              schema={comp.schema}
              userPropertyAdjustments={comp.userPropertyAdjustments}
              // Can't figure out how to avoid casting but we can be confident this type is correct
              field={field as PropertyStateFields | PropertyStateFields[]}
            />
          );
        }
      })}
    </tr>
  );
};

export const CompsCompareTable = ({
  dataHcName,
  subject,
  comps,
  fields,
  subjectPhotoUrl,
  compPhotoUrls,
  // Used to properly label comp lists that span multiple pages
  pageIndex = 0,
}: CompsCompareTableProps) => {
  return (
    <table data-hc-name={dataHcName} className={styles.Table}>
      <tbody>
        <tr>
          <td />
          <td
            className={classNames(styles.Td, styles.ColLabel, styles.subject)}
          >
            Subject
          </td>
          {comps.map((comp, index) => (
            <td
              key={`label-${
                comp.schema.compID || comp.schema.propertyState.hcAddressId
              }-${index}`}
              className={classNames(styles.Td, styles.ColLabel)}
              data-hc-name={`${dataHcName}-comp-label-${index + 1}`}
            >
              Comp {pageIndex * MAX_COMPS_PER_PAGE_COMPARE + (index + 1)}
            </td>
          ))}
        </tr>
        <tr>
          <th />
          <td>
            {subjectPhotoUrl ? (
              <ImgFit
                dataHcName={`${dataHcName}-subject-photo`}
                src={subjectPhotoUrl}
                objectFit="cover"
                width={120}
                height={110}
              />
            ) : compPhotoUrls ? (
              <NoImagePlaceHolder
                dataHcName={`${dataHcName}-subject-no-image`}
              />
            ) : null}
          </td>
          {(compPhotoUrls || subjectPhotoUrl) &&
            comps.map((comp, i) => {
              const { compID } = comp.schema;
              const photo = compPhotoUrls?.[compID];
              if (photo) {
                return (
                  <td key={`${dataHcName}-comp-photo-${compID}`}>
                    <ImgFit
                      dataHcName={`${dataHcName}-comp-photo`}
                      src={photo}
                      objectFit="cover"
                      width={120}
                      height={110}
                    />
                  </td>
                );
              } else {
                return (
                  <td key={`${dataHcName}-comp-no-image-${compID}-${i}`}>
                    <NoImagePlaceHolder
                      dataHcName={`${dataHcName}-comp-no-image`}
                    />
                  </td>
                );
              }
            })}
        </tr>
        <tr>
          <th
            data-hc-name={`${dataHcName}-header-address`}
            className={styles.Th}
          >
            Address
          </th>
          <AddressCell
            dataHcName={`${dataHcName}-subject-address`}
            schema={subject.schema}
          />
          {comps.map((comp, i) => {
            const dataHcNameAddress = `${dataHcName}-${comp.schema.compID}-${i}-address`;
            return (
              <AddressCell
                dataHcName={dataHcNameAddress}
                key={dataHcNameAddress}
                schema={comp.schema}
              />
            );
          })}
        </tr>
        {fields.map((field, idx) => {
          return (
            <CompsTableRow
              key={`row-${field}-${idx}`}
              field={field}
              subjectSchema={subject.schema}
              comps={comps.map((comp) => ({
                type: 'comp',
                schema: comp.schema,
                userPropertyAdjustments: comp.userPropertyAdjustments,
              }))}
            />
          );
        })}
      </tbody>
    </table>
  );
};
