import React from 'react';

import {
  PexpCompsPages,
  PexpGlossaryContinuedPage,
  PexpGlossaryPage,
  PexpMarketPage,
  PexpRentalCompsMapPage,
  PexpSubjectCommentsPages,
  PexpSubjectDetailsPage,
  PexpUserOpinionOfPriceCommentsPages,
  PropertyReportCoverPage,
  PropsPexpSubjectDetailsPage,
} from '../pdf-pages';

export interface ReportApiValuePdfProps {
  propsPropsPexpSubjectDetailsPage?: PropsPexpSubjectDetailsPage;
}

// The customizable component is consumed by other templates
export const ReportApiValuePdfWithProps = ({
  propsPropsPexpSubjectDetailsPage,
}: ReportApiValuePdfProps) => {
  console.log('Rendering reportApi-value PDF');
  return (
    <>
      <PropertyReportCoverPage />
      <PexpSubjectDetailsPage {...propsPropsPexpSubjectDetailsPage} />
      <PexpSubjectCommentsPages />
      <PexpUserOpinionOfPriceCommentsPages />
      <PexpCompsPages />
      <PexpRentalCompsMapPage />
      <PexpMarketPage />
      <PexpGlossaryPage />
      <PexpGlossaryContinuedPage />
    </>
  );
};

// The default PDF gets a lazy-import in the index file
export const ReportApiValuePdf = () => {
  console.log('Rendering reportApi-value PDF Lower');
  return <ReportApiValuePdfWithProps />;
};
