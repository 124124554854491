import React from 'react';

import { SatelliteStaticImage } from '@hcs/pdf/maps';
import { NullState } from '@hcs/pdf/pdf-service';
import { Section } from '@hcs/pdf/pdf-service';
import { locationToGeoLocation } from '@hcs/utils';

import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './SubjectSatelliteImage.module.css';

export const SubjectSatelliteImage = () => {
  const subjectDoc = useSubjectDocument();
  const subjectGeo = locationToGeoLocation(
    subjectDoc.data.propertyState.location,
  );
  const dataHcName = 'subject-satellite-image';

  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Photos - Aerial Photo of Property' }}
    >
      <div className={styles.MapContainer}>
        {subjectGeo ? (
          <SatelliteStaticImage
            dataHcName={dataHcName}
            geoLocation={subjectGeo}
          />
        ) : (
          <NullState dataHcName={`${dataHcName}-null`} />
        )}
      </div>
    </Section>
  );
};
