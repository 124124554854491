import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { CompFields } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { BiasRisk } from '../..//component/BiasRisk/BiasRisk';
import { useAppraisalCompStats } from '../..//hooks/useAppraisalCompStats';

import styles from './AppraisalReviewCompStats.module.css';

const { Display: DisplayDistance } = COMP_FIELDS_CONFIG[CompFields.distance];

const ICON_PROPS = {
  height: 15,
};
const Metric = ({
  label,
  hc,
  appraisal,
  annotation,
}: {
  label: ReactNode;
  hc: ReactNode;
  annotation?: ReactNode;
  appraisal: ReactNode;
}) => {
  return (
    <div className={styles.MetricGroup}>
      <div className={styles.Label}>{label}</div>
      <div className={styles.Annotation}>{annotation}</div>
      <div className={styles.Metric}>{appraisal}</div>
      <div className={classNames(styles.Metric, styles.hc)}>{hc}</div>
    </div>
  );
};

const dataHcName = 'apprailal-review-comp-stats';
export const AppraisalReviewCompStats = () => {
  const stats = useAppraisalCompStats();
  const numOverlap = Object.keys(stats.overlap).length;
  return (
    <div data-hc-name={dataHcName} className={styles.AppraisalReviewCompStats}>
      <Metric
        label={<span className={styles.TableLabel}>Comparables</span>}
        hc="HouseCanary"
        appraisal="Appraisal"
        annotation=""
      />
      <Metric
        label="High Similarity Comps"
        hc={formatNumber(stats.hc.highSimComps)}
        appraisal={formatNumber(stats.appraisal.highSimComps)}
        annotation={
          <BiasRisk
            includeIcon
            className={styles.Risk}
            iconProps={ICON_PROPS}
            riskType="comparables"
            riskLevel={
              numOverlap || stats.appraisal.highSimComps ? 'LOW_RISK' : 'RISK'
            }
          >{`${formatNumber(numOverlap)} Suggested Comp${
            numOverlap === 1 ? '' : 's'
          } Selected`}</BiasRisk>
        }
      />
      <Metric
        label="Average Similarity"
        hc={formatNumber(stats.hc.avgSimilarity)}
        appraisal={formatNumber(stats.appraisal.avgSimilarity)}
        annotation={
          <BiasRisk
            includeIcon
            className={styles.Risk}
            iconProps={ICON_PROPS}
            riskType="comparables"
            riskLevel={
              stats.appraisal.avgSimilarity > 85
                ? 'LOW_RISK'
                : stats.appraisal.avgSimilarity < 75
                  ? 'HIGH_RISK'
                  : 'RISK'
            }
          >{`${
            stats.appraisal.avgSimilarity > 85
              ? 'High'
              : stats.appraisal.avgSimilarity < 75
                ? 'Low'
                : 'Moderate'
          } Average Similarity`}</BiasRisk>
        }
      />
      <Metric
        label="Average Distance"
        hc={
          <DisplayDistance
            comp={{ compID: '_spoof_id_d', distance: stats.hc.avgDistance }}
          />
        }
        appraisal={
          <DisplayDistance
            comp={{
              compID: '_spoof_id_d',
              distance: stats.appraisal.avgDistance,
            }}
          />
        }
        annotation={
          <BiasRisk
            includeIcon
            className={styles.Risk}
            iconProps={ICON_PROPS}
            riskType="comparables"
            riskLevel={
              stats.appraisal.avgDistance < 0.5
                ? 'LOW_RISK'
                : stats.appraisal.avgDistance > 1
                  ? 'HIGH_RISK'
                  : 'RISK'
            }
          >
            {stats.appraisal.avgDistance < 0.5
              ? 'Very Close Comparables'
              : stats.appraisal.avgDistance > 1
                ? 'Far Comparables'
                : 'Moderately Close Comparables'}
          </BiasRisk>
        }
      />
    </div>
  );
};
