import {
  ComparableValue,
  ValuationMethod,
  ValuationMethodLabel,
  ValuationMethodLabelRental,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';
import { ValueExtended } from '@hcs/types';

import { useSubjectValueDocument } from './useSubjectValueDocument';

interface CompResult {
  selectedValue: ComparableValue;
  valuationMethod: ValuationMethod.Comps;
  label: string;
}

interface OtherResults {
  selectedValue: ValueExtended | undefined;
  valuationMethod: Exclude<ValuationMethod, ValuationMethod.Comps>;
  label: string;
}
export const useReportValue = <D extends ReportApiReports>(
  showRentalValue?: boolean,
): CompResult | OtherResults | undefined => {
  const subjectValueDocument = useSubjectValueDocument<D>(showRentalValue);
  if (subjectValueDocument) {
    if (subjectValueDocument?.data.selectedValue === ValuationMethod.Comps) {
      return {
        selectedValue: subjectValueDocument.data[ValuationMethod.Comps],
        valuationMethod: subjectValueDocument.data.selectedValue,
        label: showRentalValue
          ? ValuationMethodLabelRental[subjectValueDocument.data.selectedValue]
          : ValuationMethodLabel[subjectValueDocument.data.selectedValue],
      };
    } else {
      const selectedValue =
        subjectValueDocument.data[subjectValueDocument.data.selectedValue];
      return {
        selectedValue: selectedValue == null ? undefined : selectedValue,
        valuationMethod: subjectValueDocument.data.selectedValue,
        label: showRentalValue
          ? ValuationMethodLabelRental[subjectValueDocument.data.selectedValue]
          : ValuationMethodLabel[subjectValueDocument.data.selectedValue],
      };
    }
  }
  return undefined;
};
