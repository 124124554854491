import React from 'react';

import { Glossary } from '@hcs/pdf/glossary';
import { Section } from '@hcs/pdf/pdf-service';
import { GlossaryTerms, GlossaryTermsProp } from '@hcs/types';
import { ValuationMethod } from '@hcs/types';

import { useReportValue } from '../../hooks/useReportValue';
import { useSubjectValueDocument } from '../../hooks/useSubjectValueDocument';
import { getGlossaryTermByValuationMethod } from '../../utils/glossary.utils';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

interface Props {
  glossaryTerms?: GlossaryTermsProp;
}
const dataHcName = 'pexp-glossary-page';
export const PexpGlossaryPage = (props: Props) => {
  const reportValue = useReportValue(false);
  const reportValueRental = useReportValue(true);
  const subjectValueDoc = useSubjectValueDocument();
  const subjectValueDocRental = useSubjectValueDocument(true);

  const rpiAdjustment =
    subjectValueDocRental?.data.comparableValue.hcAdjustments
      .hcAdjustmentDate || null;
  const hpiAdjustment =
    subjectValueDoc?.data.comparableValue.hcAdjustments.hcAdjustmentDate ||
    null;
  const hcAdjustment =
    subjectValueDoc?.data.comparableValue.hcAdjustments
      .hcAdjustmentPropertyDetails || null;

  const reportValueGlossaryTerm =
    getGlossaryTermByValuationMethod(
      // Handle special comp methods
      reportValue?.valuationMethod || ValuationMethod.Avm,
    ) || GlossaryTerms.HcValue;

  const rentalReportValueGlossaryTerm = reportValueRental
    ? getGlossaryTermByValuationMethod(
        // Handle special comp methods
        reportValueRental?.valuationMethod || ValuationMethod.Avm,
        true,
      )
    : undefined;

  const valueTerms: GlossaryTermsProp = [];
  if (reportValueGlossaryTerm === GlossaryTerms.HcValue) {
    valueTerms.push({
      type: 'avm',
      glossaryTerm: reportValueGlossaryTerm,
      avm: reportValue?.selectedValue,
    });
  } else if (
    reportValueGlossaryTerm === GlossaryTerms.ComparableValueSelected
  ) {
    valueTerms.push({
      type: 'compValue',
      glossaryTerm: reportValueGlossaryTerm,
      compValueOptions: {
        hpiRpiAdjustment: hpiAdjustment,
        hcAdjustment,
      },
    });
  } else {
    valueTerms.push(reportValueGlossaryTerm);
  }

  if (rentalReportValueGlossaryTerm) {
    if (rentalReportValueGlossaryTerm === GlossaryTerms.RentalHcValue) {
      valueTerms.push({
        type: 'avm',
        glossaryTerm: rentalReportValueGlossaryTerm,
        avm: reportValueRental?.selectedValue,
      });
    } else if (
      rentalReportValueGlossaryTerm ===
      GlossaryTerms.RentalComparableValueSelected
    ) {
      valueTerms.push({
        type: 'compValue',
        glossaryTerm: rentalReportValueGlossaryTerm,
        compValueOptions: {
          hpiRpiAdjustment: rpiAdjustment,
          hcAdjustment,
        },
      });
    } else {
      valueTerms.push(rentalReportValueGlossaryTerm);
    }
  }

  const glossaryTerms: GlossaryTermsProp = props.glossaryTerms || [
    ...valueTerms,
    GlossaryTerms.Active,
    GlossaryTerms.CompProperties,
    GlossaryTerms.CurrentValue,
    GlossaryTerms.DaysOnMarket,
    GlossaryTerms.ForecastStandardDeviation,
    GlossaryTerms.HcSuggestedComps,
    GlossaryTerms.RiskOfDecline,
    GlossaryTerms.MonthsOfSupply,
  ];
  return (
    <PropertyReportPage dataHcName={dataHcName}>
      <Section
        dataHcName={`${dataHcName}-section`}
        sectionHeaderProps={{
          title: `Glossary`,
        }}
      >
        <Glossary glossaryTerms={glossaryTerms} />
      </Section>
    </PropertyReportPage>
  );
};
