import { AppraisalCompDataSourcesDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from '../hooks/useDocumentRole';

export const useAppraisalCompDataSourcesDocuments = <
  Data extends ReportApiReports,
>() => {
  return useDocumentRole<AppraisalCompDataSourcesDocument, Data>(
    DocumentRoles.AppraisalCompDataSources,
  );
};
