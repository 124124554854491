import { sortCompDocuments } from '@hcs/report-api';
import { DocumentRoles, RentalCompDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useRentalCompDocuments = <Data extends ReportApiReports>() => {
  const rentalCompDocuments = useDocumentRole<RentalCompDocument, Data>(
    DocumentRoles.RentalComp,
  );
  rentalCompDocuments.sort(sortCompDocuments);
  return rentalCompDocuments;
};
