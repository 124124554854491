import { sortCompDocuments } from '@hcs/report-api';
import { CompTypes } from '@hcs/types';
import { CompIdentifierId, CompSchema, DocumentRoles } from '@hcs/types';
import { UserPropertyAdjustments } from '@hcs/types';

import { useRentalCompDocuments } from '../hooks/useRentalCompDocuments';

import { useHcSuggestedRentalCompDocuments } from './useHcSuggestedRentalCompDocuments';

interface SelectedOrSuggestedComp {
  documentId?: string | null;
  compIdentifier: CompIdentifierId;
  compSchema: CompSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}

interface SelectedOrSuggestedCompsData {
  isSuggested: boolean;
  isSelected: boolean;
  compType: CompTypes;
  comps: SelectedOrSuggestedComp[];
}

export const useSelectedOrSuggestedRentalComps = (
  compType: CompTypes = CompTypes.Rental,
): SelectedOrSuggestedCompsData => {
  const hcSuggestedCompsDocuments = useHcSuggestedRentalCompDocuments();
  const compDocuments = useRentalCompDocuments();
  const isSuggested = !compDocuments?.length;
  const isSelected = !isSuggested;
  // Pick comps from either comp documents or most similar from farm
  // Suggested comps are sorted by similarity
  const comps: SelectedOrSuggestedComp[] =
    (compDocuments?.length ? compDocuments : hcSuggestedCompsDocuments)
      .sort(sortCompDocuments)
      .map((compDocument) => ({
        documentId: compDocument.documentId,
        compSchema: compDocument.data,
        compIdentifier: {
          type: 'compId',
          compId: compDocument.data.compID,
          compType,
        },
        userPropertyAdjustments:
          compDocument.role === DocumentRoles.RentalComp
            ? compDocument.userPropertyAdjustments
            : undefined,
      })) || [];

  return {
    isSuggested,
    isSelected,
    comps,
    compType,
  };
};
