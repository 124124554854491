import { DocumentRoles, SubjectPhotosDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSubjectPhotosDocument = <Data extends ReportApiReports>() => {
  return useSingleDocumentRole<SubjectPhotosDocument, Data>(
    DocumentRoles.SubjectPhotos,
  );
};
