import React, { useCallback } from 'react';

import { Addendum, usePdfAddendum } from '@hcs/pdf/pdf-service';
import { CompTypes, ListingStatus } from '@hcs/types';

import { CompsStatus, useTop4Comps } from '../../hooks/useTop4Comps';

import styles from './Top4CompsRemark.module.css';
export interface Top4CompsRemarkProps {
  compsStatus: CompsStatus;
}
export const Top4CompsRemark = ({ compsStatus }: Top4CompsRemarkProps) => {
  const {
    actions: { addItemToList },
  } = usePdfAddendum();
  const { comps } = useTop4Comps({
    compsStatus,
    compType: CompTypes.Sold,
  });
  const compsStatusTitle =
    compsStatus === ListingStatus.ACTIVE ? 'Listing' : 'Sold';

  const handleContentOverflow = useCallback(
    (title: string, remark: string) => {
      addItemToList({
        term: title,
        description: remark,
        source: 'Conclusion (page 8)',
      });
    },
    [addItemToList],
  );

  if (!comps.length) {
    return <p>{compsStatusTitle} Comps comment is currently unavailable.</p>;
  }

  return (
    <div className={styles.Top4CompsRemarkContainer}>
      {comps.map((comp, index) => {
        const title = `${compsStatusTitle} Comp ${index + 1}`;
        const remark =
          (comp.compSchema.propertyState?.listingDetailsSale?.remarks?.[0]
            ?.value as string) || 'No comments available';

        return (
          <div key={comp.compSchema.compID} className={styles.InnerContainer}>
            <div className={styles.Title}>{title}</div>
            <Addendum
              handleOverflow={() => handleContentOverflow(title, remark)}
            >
              {remark || 'No comments available'}
            </Addendum>
          </div>
        );
      })}
    </div>
  );
};
