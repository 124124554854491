import { buildStaticStreetViewUrlForPdf } from '@hcs/street-view';
import { ReportApiReports } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useSubjectDocument } from './useSubjectDocument';
import { useSubjectPhotosDocument } from './useSubjectPhotosDocument';

export const useSubjectFrontPhoto = <D extends ReportApiReports>() => {
  const subjectPhotosDocument = useSubjectPhotosDocument<D>();
  const subjectDocument = useSubjectDocument();
  const subjectGeoLocation = locationToGeoLocation(
    subjectDocument.data.propertyState.location,
  );
  const firstPhoto =
    subjectPhotosDocument?.data?.listingPhotos?.[0]?.media?.images?.[0]?.url;
  return firstPhoto
    ? firstPhoto
    : subjectGeoLocation
      ? buildStaticStreetViewUrlForPdf(subjectGeoLocation, {
          width: 417,
          height: 274,
        })
      : undefined;
};
