import {
  REPORT_BASE_PROPERTY_STATE_FIELDS,
  REPORT_FEATURE_CONFIGS,
} from '@hcs/report-api';
import { fieldsToSupportMapping } from '@hcs/report-api';
import { PdfTemplateIds } from '@hcs/types';
import {
  ReportConfig,
  ReportConfigApi,
  ReportConfigSlugs,
  ReportFeatures,
  ReportTypes,
} from '@hcs/types';

import { useReport } from '../hooks/useReport';

// TODO: Remove when legacy reports are no longer supported
export const REPORT_CONFIG_PROPERTY_EXPLORER_LEGACY: ReportConfigApi = {
  productName: 'Property Explorer',
  reportName: 'Valuation Report',
  reportConfigSlug: ReportConfigSlugs.PexpDynamic,
  pdfTemplateId: PdfTemplateIds.ReportApiLegacy,
  reportType: ReportTypes.Value,
  clientEditable: true,
  // Important applicationName / ehrmantrautComponentName need to stay in sync
  applicationName: 'Value Report', // AppSlugs in the FE currently
  applicationSlug: 'property-explorer',
  componentName: 'Value Report',
  ehrmantrautComponentName: 'Value Report',
  reportFeatures: [
    ReportFeatures.DexpAnalysisComps,
    ReportFeatures.DexpAnalysisRentalComps,
    ReportFeatures.AvmBreakdown,
    ReportFeatures.NearbyPropertiesChart,
    ReportFeatures.SimilarPropertiesChart,
    ReportFeatures.MonthsOfSupplyChart,
    ReportFeatures.DaysOnMarketChart,
    ReportFeatures.OutputPdf,
    ReportFeatures.OutputExcel,
    ReportFeatures.MlsSheet,
    ReportFeatures.Heatmaps,
    ReportFeatures.SubjectStreetview,
    ReportFeatures.SubjectPhotosCurrent,
    ReportFeatures.SubjectPhotosAll,
    ReportFeatures.HcAvm,
    ReportFeatures.ComparableValue,
    ReportFeatures.AdjustedAvm,
    ReportFeatures.UserOpinionOfPrice,
    ReportFeatures.RentalHcAvm,
    ReportFeatures.RentalComparableValue,
    ReportFeatures.RentalUserOpinionOfPrice,
    ReportFeatures.CompsHcSuggested,
    ReportFeatures.CompsSelect,
    ReportFeatures.CompsAdjust,
    ReportFeatures.CompsSavedFilterSets,
    ReportFeatures.CompsEditDetails,
    ReportFeatures.CompsAdjustedAvm,
    ReportFeatures.CompsFilterGeojson,
    ReportFeatures.CompsFilterKeyword,
    ReportFeatures.CompsFilterDistance,
    ReportFeatures.CompsSelectByAddress,
    ReportFeatures.CompsSelectByListing,
    ReportFeatures.CompsHcAdjustmentDate,
    ReportFeatures.CompsHcAdjustmentDetails,
    ReportFeatures.CompsListingRemarks,
    ReportFeatures.CompsNearbyFilteredCharts,
    ReportFeatures.CompsSimilarPropertiesCharts,
    ReportFeatures.CompsPhotosCurrent,
    ReportFeatures.CompsTransactionHistory,
    ReportFeatures.CompsStreetview,
    ReportFeatures.Tags,
    ReportFeatures.ReportDataRefresh,
    ReportFeatures.SubjectTransactionHistory,
    ReportFeatures.SubjectForecastZipcode,
    ReportFeatures.SubjectForecastBlock,
    ReportFeatures.SubjectPropertyDetails,
    ReportFeatures.SubjectDataSources,
    ReportFeatures.SubjectListingRemarks,
    ReportFeatures.SubjectEditDetails,
    ReportFeatures.SubjectEditCondition,
    ReportFeatures.SubjectComment,
    ReportFeatures.CobrandedReport,
    ReportFeatures.PublicLinks,
    ReportFeatures.LimitedReportGeolocation,
    ReportFeatures.LimitedReportAvm,
    ReportFeatures.LimitedReportMls,
    ReportFeatures.RentalCompsHcSuggested,
    ReportFeatures.RentalCompsSelect,
    ReportFeatures.RentalCompsAdjust,
    ReportFeatures.RentalCompsSavedFilterSets,
    ReportFeatures.RentalCompsEditDetails,
    ReportFeatures.RentalCompsFilterGeojson,
    ReportFeatures.RentalCompsFilterKeyword,
    ReportFeatures.RentalCompsFilterDistance,
    ReportFeatures.RentalCompsSelectByAddress,
    ReportFeatures.RentalCompsSelectByListing,
    ReportFeatures.RentalCompsHcAdjustmentDate,
    ReportFeatures.RentalCompsHcAdjustmentDetails,
    ReportFeatures.RentalCompsListingRemarks,
    ReportFeatures.RentalCompsPhotosCurrent,
    ReportFeatures.RentalCompsTransactionHistory,
    ReportFeatures.RentalCompsStreetview,
    ReportFeatures.MarketAnalysisChart,
    ReportFeatures.IntraOrgLinks,
  ],
};
export const useReportConfig = (): ReportConfig => {
  const report = useReport();
  const reportConfigBase =
    report.reportConfig || REPORT_CONFIG_PROPERTY_EXPLORER_LEGACY;
  if (report.reportFeatures) {
    // Replace report features on the config with the features that were enabled when the report was created
    reportConfigBase.reportFeatures = report.reportFeatures;
  }
  const reportFeaturesSupport: ReportConfig['reportFeaturesSupport'] = {};
  const fieldsSupport: ReportConfig['fieldsSupport'] = fieldsToSupportMapping(
    REPORT_BASE_PROPERTY_STATE_FIELDS,
  );
  reportConfigBase.reportFeatures.forEach((reportFeature) => {
    reportFeaturesSupport[reportFeature] = true;
    const { fields } = REPORT_FEATURE_CONFIGS[reportFeature];
    if (fields) {
      fields.forEach((f) => {
        fieldsSupport[f] = true;
      });
    }
  });
  return {
    ...reportConfigBase,
    reportFeaturesSupport,
    fieldsSupport,
    // Not needed in PDFs
    documentRoles: {},
    reportFeaturesByType: {},
  };
};
