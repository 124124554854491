import React from 'react';

import { TwoColTable } from '@hcs/pdf/pdf-service';
import { formatMissing, formatMoney } from '@hcs/utils';

import { useReport, useSubjectDocument } from '../../hooks';

import styles from './BrokerPriceOpinionTable.module.css';

const dataHcName = 'broker-price-opinion';
export const BrokerPriceOpinionTable = () => {
  const report = useReport();
  const subjectDocument = useSubjectDocument();
  const currentStatus = formatMissing(
    subjectDocument?.data?.propertyState?.complexFieldsSale?.currentStatus,
  );
  const fullName = formatMissing(report.realtorDetails?.fullName);
  const firm = formatMissing(report.realtorDetails?.firm);
  const landValue = formatMoney(
    subjectDocument?.data?.propertyState?.land?.valueLand,
  );
  const listPrice = formatMoney(
    subjectDocument?.data?.propertyState?.complexFieldsSale?.currentPrice,
  );

  return (
    <div className={styles.TableContainer}>
      <TwoColTable
        dataHcName={`${dataHcName}-table-left`}
        data={[
          {
            label: 'Broker Name',
            value: fullName,
            dataHcName: 'broker-name',
          },
          {
            label: 'Broker Firm',
            value: firm,
            dataHcName: 'broker-firm',
          },
          {
            label: 'Land Value',
            value: landValue,
            dataHcName: 'land-value',
          },
          {
            label: 'Last List Price',
            value: listPrice,
            dataHcName: 'last-list-price',
          },
          {
            label: 'Status',
            value: currentStatus,
            dataHcName: 'status',
          },
        ]}
      />
    </div>
  );
};
