import { AppraisalInputDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useAppraisalInputDocument = <Data extends ReportApiReports>() => {
  return useSingleDocumentRole<AppraisalInputDocument, Data>(
    DocumentRoles.AppraisalInput,
  );
  // return {
  //   createdAt: '2023-03-06T07:27:40.91602Z',
  //   data: {
  //     amc: 'No AMC',
  //     appraiser: {
  //       company: 'Apple Appraisal, Inc.',
  //       email: 'ablackburn@goapple.com',
  //       license: {
  //         expirationDate: '2024-01-23',
  //         identifier: 'AR002124',
  //         state: 'CA',
  //         type: 'Certificate'
  //       },
  //       name: 'Anthony L. Blackburn',
  //       phone: '925-313-5900'
  //     },
  //     borrowerName: 'Melissa Antoun',
  //     lenderName: 'Envoy Mortgage Ltd.'
  //   },
  //   deletedAt: null,
  //   documentId: '4fe808e0-4efd-462b-abcd-21a22bea23f0',
  //   id: 946,
  //   revisionId: 0,
  //   role: 'appraisal_input',
  //   schemaId: 'appraisal_input',
  //   updatedAt: '2023-03-06T07:27:40.91602Z'
  // };
};
