import React from 'react';

import {
  HeaderDetail,
  PageHeader,
  PageHeaderProduct,
} from '@hcs/pdf/pdf-service';
import { COUNTY_CONFIG } from '@hcs/property-state';
import { PropertyReports, PropertyStateType } from '@hcs/types';
import {
  formatCityStateZip,
  formatDateShort,
  formatStreetAddress,
} from '@hcs/utils';

import { useReport } from '../../hooks/useReport';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import styles from './PropertyReports.module.css';

const { formatValueShort } = COUNTY_CONFIG;
const dataHcName = 'bpo-report-page-header';
export const BpoReportPageHeader = <D extends PropertyReports>() => {
  const { productName } = useReportConfig();
  const subjectDocument = useSubjectDocument<D>();
  const report = useReport<D>();
  const headerDetails: HeaderDetail[] = [
    {
      dataHcName: `${dataHcName}-housecanary-file-id`,
      label: 'HouseCanary File ID #',
      value: report.orderDetails?.hcFileId,
    },
    {
      dataHcName: `${dataHcName}-client-file-id`,
      label: 'Client File ID #',
      value: report.clientID,
    },
    {
      dataHcName: `${dataHcName}-order-name`,
      label: 'Order Name',
      value: report.orderDetails?.orderName,
    },
    {
      dataHcName: `${dataHcName}-ordered-date`,
      label: 'Ordered Date',
      value: formatDateShort(report.orderDetails?.orderedAt),
    },
  ];
  return (
    <>
      <PageHeader headerDetails={headerDetails} />
      <PageHeaderProduct
        productName={productName}
        leftContentLabel={formatStreetAddress(
          subjectDocument?.data.propertyState.location,
        )}
        leftContentValue={formatCityStateZip(
          subjectDocument?.data.propertyState.location,
        )}
      >
        {subjectDocument?.data.propertyState.location && formatValueShort && (
          <>
            <span
              data-hc-name={`${dataHcName}-left-county-label`}
              className={styles.CountyLabel}
            >
              County:
            </span>
            <span
              data-hc-name={`${dataHcName}-left-county-value`}
              className={styles.CountyValue}
            >
              {formatValueShort({
                propertyStateType: PropertyStateType.Core,
                propertyState: subjectDocument?.data.propertyState,
              })}
            </span>
          </>
        )}
      </PageHeaderProduct>
    </>
  );
};
