import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import PropTypes from 'prop-types';

import { forceLayoutMarkerPositions, MarkerProperty } from '@hcs/maps';
import { SimilarityLevelLabel } from '@hcs/pdf/pdf-service';
import { SubjectMarker } from '@hcs/pdf/pdf-service';
import { SimilarityLevel } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useAppraisalCompDocuments } from '../..//hooks/useAppraisalCompDocuments';
import { useCompsForAppraisalCompare } from '../..//hooks/useCompsForAppraisalCompare';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

const MARKER_RADIUS = 15;
const PointSvg = () => (
  <svg
    width="6"
    height="6"
    viewBox={`0 0 6 6`}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: 'absolute',
    }}
  >
    <ellipse id="svg_2" ry="3" rx="3" cy="3" cx="3" fill="#070707" />
  </svg>
);

const tmpLegacySimilarityLevel = (
  similarityLevel?: SimilarityLevel | null | '',
): 'high' | 'moderate' | 'low' | undefined =>
  similarityLevel
    ? (similarityLevel.toLowerCase() as 'high' | 'moderate' | 'low')
    : undefined;
type FeatureGroupContext = {
  map: unknown;
};
export const AppraisalReviewCompsMapMarkers = (
  props: Record<string, unknown>,
  context: FeatureGroupContext,
) => {
  const hcSuggestedCompDocs = useCompsForAppraisalCompare();
  const appraisalCompDocs = useAppraisalCompDocuments();
  const subjectDoc = useSubjectDocument();
  const geoLocation = locationToGeoLocation(
    subjectDoc.data.propertyState.location,
  );
  const appraisalCompMarkerInputs: MarkerProperty[] = [];
  const compMarkerInputs: MarkerProperty[] = [];
  hcSuggestedCompDocs.forEach((compDoc, idx) => {
    const compGeoLocation = locationToGeoLocation(
      compDoc.data.propertyState.location,
    );
    if (compGeoLocation) {
      compMarkerInputs.push({
        geoLocation: compGeoLocation,
        similarityLevel: tmpLegacySimilarityLevel(
          compDoc.data.similarity.levelAdjusted,
        ),
        highlight: 'hc',
        // label: formatMissing(compDoc.data.similarity.scoreAdjusted),
        order: idx,
        listingStatus: 'Sold' as 'Sold' | 'Active' | null,
      });
    }
  });
  appraisalCompDocs.forEach((compDoc, idx) => {
    const compGeoLocation = locationToGeoLocation(
      compDoc.data.propertyState.location,
    );
    if (compGeoLocation) {
      appraisalCompMarkerInputs.push({
        geoLocation: compGeoLocation,
        similarityLevel: tmpLegacySimilarityLevel(
          compDoc.data.similarity.levelAdjusted,
        ),
        highlight: 'appraisal',
        order: idx,
        label: idx.toString(),
        // label: formatMissing(compDoc.data.similarity.scoreAdjusted),
        listingStatus: 'Sold' as 'Sold' | 'Active' | null,
      });
    }
  });
  const compMarkers = geoLocation
    ? forceLayoutMarkerPositions([
        {
          geoLocation,
        },
        ...compMarkerInputs,
        ...appraisalCompMarkerInputs,
      ])
    : [];
  const PointLayers = compMarkers.map(({ propertyPosition }) =>
    L.marker(propertyPosition, {
      icon: L.divIcon({
        className: 'marker__container',
        iconSize: [6, 6],
        iconAnchor: [3, 3],
        html: renderToString(<PointSvg />),
      }),
    }),
  );
  const LineLayers = compMarkers.map(({ labelPosition, propertyPosition }) =>
    L.polyline([propertyPosition, labelPosition], {
      weight: 1,
      color: 'black',
    }),
  );
  const LabelLayers = compMarkers.map(
    ({ labelPosition, similarityLevel, highlight, label, id }) =>
      id === 0
        ? L.marker(labelPosition, {
            icon: L.divIcon({
              className: 'label__container',
              iconSize: [22, 22],
              iconAnchor: [11, 11],
              html: renderToString(
                <SubjectMarker
                  style={{
                    height: '22px',
                    width: '22px',
                  }}
                />,
              ),
            }),
          })
        : L.marker(labelPosition, {
            icon: L.divIcon({
              className: 'label__container',
              iconSize: [22, 22],
              iconAnchor: [11, 11],
              html: renderToString(
                <SimilarityLevelLabel
                  radius={MARKER_RADIUS}
                  dataHcName="label"
                  highlight={highlight}
                  label={label || id}
                  similarityLevel={
                    similarityLevel as null | undefined | SimilarityLevel
                  }
                />,
              ),
            }),
          }),
  );
  useEffect(() => {
    const featureGroup = L.featureGroup([
      ...PointLayers,
      ...LineLayers,
      ...LabelLayers,
    ]).addTo(context.map as L.Map);
    // hack to fix DNV-488 https://housecanary.atlassian.net/browse/DNV-448
    // the hcmaps-leaflet sets the position using a timeout which sets the bounds
    // we want to reset them here, so we also have to use setTimeout
    // better solution would be to do Layers how the library expects/intends (as children that the Map component renders)
    setTimeout(() => {
      (context.map as L.Map).fitBounds(featureGroup.getBounds());
    });
  });
  return null;
};
// Note: Relies on the deprecated context api since hcmaps-leaflet uses this api
// to provide the map context. This api will be removed in React v17
// https://reactjs.org/docs/legacy-context.html
AppraisalReviewCompsMapMarkers.contextTypes = {
  map: PropTypes.object,
};
