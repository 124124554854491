import { ReportApiDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useSingleDocumentRole = <
  DocType extends ReportApiDocument,
  Data extends ReportApiReports,
>(
  documentRole: DocType['role'],
): DocType | undefined => {
  const documents = useDocumentRole<DocType, Data>(documentRole);
  return documents[0] || undefined;
};
