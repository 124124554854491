import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import {
  AppraisalSubjectValueDocument,
  AppraisalXmlDocument,
  DocumentRoles,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSubjectDocument } from '../hooks/useSubjectDocument';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useAppraisalSubjectValueDocument = <
  Data extends ReportApiReports,
>() => {
  const subjectDoc = useSubjectDocument();
  const appraisalXml = useSingleDocumentRole<AppraisalXmlDocument, Data>(
    DocumentRoles.AppraisalXml,
  );
  const sqFt = getPropertyStateFieldValue(PropertyStateFields.livingArea, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDoc.data.propertyState,
  });
  const value =
    appraisalXml?.data.payload.Valuation.PropertyAppraisedValueAmount;
  const valuePerSqFt = sqFt && value ? value / sqFt : undefined;
  return {
    data: {
      userValue: {
        calculatedFields: {
          valuePerSqFt,
        },
        value: {
          value,
        },
      },
    },
  } as AppraisalSubjectValueDocument;
};
