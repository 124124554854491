import React from 'react';

import { MAPBOX_KEY } from '@hcs/maps';
import { GeoLocation } from '@hcs/types';

interface Props {
  dataHcName: string;
  geoLocation: GeoLocation;
}

const ZOOM_LEVEL = 19;

export const SatelliteStaticImage = ({ dataHcName, geoLocation }: Props) => (
  <img
    data-hc-name={`${dataHcName}-static-image`}
    alt="Static Satellite - Subject"
    src={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/pin-l+20203f(${geoLocation.longitude},${geoLocation.latitude})/${geoLocation.longitude},${geoLocation.latitude},${ZOOM_LEVEL}/538x407/?attribution=false&access_token=${MAPBOX_KEY}`}
  />
);
