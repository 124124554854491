import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { arrayToChunks } from '@hcs/utils';

import { CompsMap } from '../../features/CompsMap';
import { CompsTable } from '../../features/CompsTable';
import { useSelectedOrSuggestedComps } from '../../hooks/useSelectedOrSuggestedComps';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

const FIELDS = [
  CompFields.distance,
  PropertyStateFields.currentStatus,
  PropertyStateFields.currentPrice,
  PropertyStateFields.currentStatusDate,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
];
export const MAX_COMPS_PER_PAGE = 10;
interface Props {
  pageIndexInit?: number;
  numPagesTotal?: number;
  compType: CompTypes;
}
export const PexpCompsMapPage = ({
  pageIndexInit = 0,
  numPagesTotal,
  compType,
}: Props) => {
  const subjectDocument = useSubjectDocument();
  const { isSuggested, comps } = useSelectedOrSuggestedComps(CompTypes.Sold);
  const dataHcName = `pexp-${
    isSuggested ? 'suggested' : 'selected'
  }-comps-map-page`;
  const subjectSchema = subjectDocument.data;
  // Do not render page if no comps
  if (!comps.length) return null;
  const compChunks = arrayToChunks(comps, MAX_COMPS_PER_PAGE);
  return (
    <>
      {compChunks.map((compDocumentsForPage, pageIndexChunk) => {
        const pageIndex = pageIndexChunk + pageIndexInit;
        const compSchemas = compDocumentsForPage.map(
          (compDoc) => compDoc.compSchema,
        );
        return (
          <PropertyReportPage
            dataHcName={`${dataHcName}-${pageIndex}`}
            key={`${dataHcName}-${pageIndex}`}
          >
            <Section
              sectionHeaderProps={{
                title: `${
                  isSuggested ? `HouseCanary Suggested` : 'Selected'
                } Sold & Active Comparables ${
                  (numPagesTotal || compChunks.length) > 1
                    ? `(${pageIndex + 1} of ${
                        numPagesTotal || compChunks.length
                      })`
                    : ''
                }`,
              }}
              dataHcName={`${dataHcName}-section`}
            >
              <CompsMap compType={compType} comps={comps} />
              <CompsTable
                dataHcName={`${dataHcName}-table`}
                subjectSchema={subjectSchema}
                rows={compSchemas.map((compSchema) => ({ compSchema }))}
                fields={FIELDS}
              />
            </Section>
          </PropertyReportPage>
        );
      })}
    </>
  );
};
