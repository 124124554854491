import { DateStr } from '@hcs/types';
import { ForecastChartSchema } from '@hcs/types';

import { useForecastChartDocument } from './useForecastChartDocument';

export interface ForecastStatsItem {
  year: number;
  date: DateStr;
  price: number;
  hpi: number;
  percentDiff: number;
}

const getNextForecast = (chart: ForecastChartSchema['chart'], year: number) => {
  if (!chart?.data) {
    return null;
  }
  const { data, currentMonthIndex } = chart;
  const forecast = data?.[12 * year + currentMonthIndex];
  if (!forecast && data?.[12 * year + currentMonthIndex - 1]) {
    // For final year, use the previous month if populated
    return data[12 * year + currentMonthIndex - 1];
  }
  return forecast;
};

const getForecastStats = (
  chart: ForecastChartSchema['chart'],
): ForecastStatsItem[] => {
  const results: ForecastStatsItem[] = [];
  let y = 1;
  let forecast = getNextForecast(chart, y);
  while (forecast) {
    results.push({
      date: forecast.x,
      year: Number(forecast.x.split('-')[0]),
      price: forecast.y,
      hpi: forecast.metaData.hpi,
      percentDiff: forecast.metaData.percentDiff,
    });
    y++;
    forecast = getNextForecast(chart, y);
  }
  return results;
};

export const useForecastStats = () => {
  const forecastChartDoc = useForecastChartDocument();

  return forecastChartDoc?.data?.chart
    ? getForecastStats(forecastChartDoc?.data.chart)
    : undefined;
};
