import React from 'react';

import { Stats } from '@hcs/design-system';
import { LineChart } from '@hcs/pdf/charts';
import { NullState } from '@hcs/pdf/pdf-service';
import { ChartSizes } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { useMonthsOfSupplyChartDocument } from '../../hooks/useMonthsOfSupplyChartDocument';

const dataHcName = 'months-of-supply-chart';
interface Props {
  chartSize?: ChartSizes;
  className?: string;
}
export const MonthsOfSupplyChart = ({ chartSize, className }: Props) => {
  const monthsOfSupplyDoc = useMonthsOfSupplyChartDocument();
  if (!monthsOfSupplyDoc?.data?.chart.data?.length) {
    return <NullState dataHcName={`${dataHcName}-null-state`} />;
  }
  return (
    <LineChart
      title="Months of Supply - ZIP"
      dataHcName={dataHcName}
      className={className}
      chartSize={chartSize}
      chartData={monthsOfSupplyDoc.data.chart.data}
      yAxis={{ label: 'Months of Supply' }}
      contentBelow={
        <Stats
          dataHcName={`${dataHcName}-stats`}
          stats={[
            {
              label: 'This month last year',
              value: formatNumber(
                monthsOfSupplyDoc.data.chart.stats.median.lastYear,
              ),
              dataHcName: 'year-prev',
            },
            {
              label: 'This month',
              value: formatNumber(
                monthsOfSupplyDoc.data.chart.stats.median.current,
              ),
              dataHcName: 'year-current',
            },
          ]}
        />
      }
    />
  );
};
