import { NullState, ValueSubSection } from '@hcs/pdf/pdf-service';
import { ValuationMethod } from '@hcs/types';
import { formatMoney, formatPricePerSqFt } from '@hcs/utils';

import { useReportValue } from '../../hooks/useReportValue';
import { useSubjectValueDocument } from '../../hooks/useSubjectValueDocument';

interface Props {
  showRentalValue?: boolean;
}

export const SubjectValue = ({ showRentalValue }: Props) => {
  const dataHcName = `subject-value${showRentalValue ? '-rental' : ''}`;
  const { selectedValue, label, valuationMethod } =
    useReportValue(showRentalValue) || {};
  const subjectValueDocument = useSubjectValueDocument(showRentalValue);
  const subjectValue = selectedValue?.value.value;
  const { valueUpper, valueLower } =
    subjectValueDocument?.data[ValuationMethod.Avm].value || {};
  const isReportValueOutOfRange =
    !subjectValue || !valueUpper || !valueLower
      ? true
      : subjectValue > valueUpper || subjectValue < valueLower;
  if (!selectedValue?.value) {
    return <NullState dataHcName={`${dataHcName}-error`} />;
  }
  return (
    <ValueSubSection
      dataHcName={dataHcName}
      title={label}
      valuesLow={{
        label: 'Low',
        value: formatMoney(selectedValue?.value.valueLower),
        secondary: formatPricePerSqFt(
          selectedValue.calculatedFields.valueLowerPerSqFt,
        ),
      }}
      valuesMiddle={{
        label: null,
        value: formatMoney(selectedValue?.value.value),
        secondary: formatPricePerSqFt(
          selectedValue.calculatedFields.valuePerSqFt,
        ),
      }}
      valuesHigh={{
        label: 'High',
        value: formatMoney(selectedValue?.value.valueUpper),
        secondary: formatPricePerSqFt(
          selectedValue.calculatedFields.valueUpperPerSqFt,
        ),
      }}
      avmQuality={selectedValue?.value.quality}
      fsd={selectedValue?.value.fsd}
      showAvmQuality={valuationMethod === ValuationMethod.Avm}
      showValueRange={valuationMethod === ValuationMethod.Avm}
      disclaimer={
        valuationMethod === ValuationMethod.Adjusted && isReportValueOutOfRange
          ? `This value is based on adjustments that have been made to
            the property's core characteristics. It does not represent a
            broker price opinion (BPO) or a formal appraisal.`
          : valuationMethod === ValuationMethod.UserEntered ||
              isReportValueOutOfRange
            ? `This value is not generated or endorsed by HouseCanary,
            and it does not represent a broker price opinion (BPO)
            or a formal appraisal.`
            : null
      }
    />
  );
};
