import { CallbackArgs, StringOrNumberOrCallback } from 'victory';

import { BarChartBucket, ChartBar } from '@hcs/types';
import { formatNumber, formatNumberAbbrev, NULL_VALUE } from '@hcs/utils';

export const barChartTick = (bucket: BarChartBucket): string =>
  `${bucket.start}-${bucket.end}`;

/** Converts internal BarChartBucket bar chart datum type to victory charts format */
export const buildVictoryChartDataBar = (
  buckets: BarChartBucket[],
): { x: string; y: number }[] => {
  return buckets.map((bucket) => ({
    x: barChartTick(bucket),
    y: bucket.count,
  }));
};

export const barChartTickValuesX = (buckets: BarChartBucket[]): string[] =>
  buckets.map((bucket) => `${bucket.start}-${bucket.end}`);

export const barChartDomainY = (
  buckets: BarChartBucket[],
): [number, number] => [0, (buckets[buckets.length - 1]?.count || 0) * 1.2];

export const barChartHighlightedTick = (chart: ChartBar) => {
  const bucket = chart.buckets[chart.subjectIndex];
  if (bucket) {
    return barChartTick(bucket);
  } else {
    return undefined;
  }
};

const BAR_CHART_LABEL_UPPER = 100;
export const barChartAxisLabelText = (
  chart: ChartBar,
): StringOrNumberOrCallback => {
  const lastBucket = chart.buckets[chart.buckets.length - 1];
  const maxValue = lastBucket?.end || lastBucket?.start || 0;
  const formatter = maxValue > 99999 ? formatNumberAbbrev : formatNumber;
  const tooManyLabels =
    (chart.buckets.length >= 6 && maxValue > 999) ||
    (chart.buckets.length >= 8 && maxValue > 99) ||
    (chart.buckets.length >= 9 && maxValue > 9);
  const labelsToShow = chart.subjectIndex % 2;
  return ({ index }: CallbackArgs) => {
    if (typeof index === 'number') {
      if (tooManyLabels && index % 2 !== labelsToShow) {
        return '';
      }
      const bucket = chart.buckets[index];
      // Split label into 2 lines if multiple labels
      // contain a large value
      const newLine =
        chart.buckets.filter(
          (bucket) =>
            bucket.start >= BAR_CHART_LABEL_UPPER ||
            bucket.end >= BAR_CHART_LABEL_UPPER,
        ).length > 1
          ? '\n'
          : '';
      if (bucket) {
        if (bucket.start === bucket.end) {
          return formatNumber(bucket.start);
        } else {
          return `${formatter(bucket.start)} -${newLine}${formatter(
            bucket.end,
          )}`;
        }
      }
    }
    return NULL_VALUE;
  };
};
