import {
  forceCollide,
  ForceLink,
  forceLink,
  forceSimulation,
  forceX,
  forceY,
  SimulationLinkDatum,
  SimulationNodeDatum,
} from 'd3-force';

import { GeoLocation, SimilarityLevelLabelProps } from '@hcs/types';

export interface MarkerProperty {
  geoLocation: GeoLocation;
  highlight?: SimilarityLevelLabelProps['highlight'];
  similarityLevel?: 'high' | 'moderate' | 'low';
  order?: number | null;
  label?: string;
  listingStatus?: 'Sold' | 'Active' | null;
}

interface Node {
  id: number;
  sourceId: number | null;
  highlight?: SimilarityLevelLabelProps['highlight'];
  x: number;
  y: number;
  fx?: number | null;
  fy?: number | null;
  label?: string;
  similarityLevel?: 'high' | 'moderate' | 'low' | null;
  order?: number | null;
  listingStatus?: 'Sold' | 'Active' | null;
}

export type PositionedMarker = {
  id?: string | number | null;
  comp?: number;
  label?: string;
  highlight?: SimilarityLevelLabelProps['highlight'];
  similarityLevel?: 'high' | 'moderate' | 'low' | null;
  propertyPosition: [number, number];
  labelPosition: [number, number];
};

interface LinkType {
  source: number;
  target: number;
}

const getGeodeticViewPort = (nodes: MarkerProperty[]) => {
  const latLngs: { latitude: number; longitude: number }[] = [];
  nodes.forEach((node) => {
    if (node.geoLocation.latitude && node.geoLocation.longitude) {
      latLngs.push({
        latitude: node.geoLocation.latitude,
        longitude: node.geoLocation.longitude,
      });
    }
  });
  const [minX, minY, maxX, maxY] = latLngs
    .map(({ latitude, longitude }): [number, number] => [latitude, longitude])
    .reduce(
      ([minX, minY, maxX, maxY], [currX, currY]) => [
        currX < minX ? currX : minX,
        currY < minY ? currY : minY,
        currX > maxX ? currX : maxX,
        currY > maxY ? currY : maxY,
      ],
      [Infinity, Infinity, -Infinity, -Infinity],
    );
  const scaleX = Math.max(maxX - minX, 0.01);
  const scaleY = Math.max(maxY - minY, 0.01);
  return {
    minX,
    minY,
    maxX,
    maxY,
    scaleX,
    scaleY,
  };
};

const normalizeCoordinates = (
  node: Node,
  [minXGeodetic, minYGeodetic, scaleXGeodetic, scaleYGeodetic]: [
    number,
    number,
    number,
    number,
  ],
) => {
  // Normalize all coords onto a (0, 1) grid since this is
  // what the layout algorithm expects
  return {
    ...node,
    x: (node.x - minXGeodetic) / scaleXGeodetic,
    y: (node.y - minYGeodetic) / scaleYGeodetic,
    fx: node.fx ? (node.x - minXGeodetic) / scaleXGeodetic : null,
    fy: node.fy ? (node.y - minYGeodetic) / scaleYGeodetic : null,
  };
};

export function forceLayoutMarkerPositions(
  properties: MarkerProperty[],
): PositionedMarker[] {
  const { minX, minY, scaleX, scaleY } = getGeodeticViewPort(properties);
  const sourceNodes: Node[] = properties.map(
    (
      {
        geoLocation: { latitude, longitude },
        label = null,
        highlight,
        similarityLevel = null,
        listingStatus = null,
        order = null,
      },
      idx,
    ) =>
      normalizeCoordinates(
        {
          id: idx,
          sourceId: null,
          x: latitude || 0,
          y: longitude || 0,
          highlight,
          label: label || undefined,
          similarityLevel,
          listingStatus,
          order,
        },
        [minX, minY, scaleX, scaleY],
      ),
  );
  const targetNodes: Node[] = properties.map(
    ({ geoLocation: { latitude, longitude } }, idx, list) =>
      normalizeCoordinates(
        {
          id: list.length + idx,
          sourceId: idx,
          x: latitude || 0,
          y: longitude || 0,
          fx: latitude,
          fy: longitude,
        },
        [minX, minY, scaleX, scaleY],
      ),
  );
  const nodes = [...sourceNodes, ...targetNodes];
  const links: LinkType[] = [];

  nodes.forEach((node) => {
    if (node.sourceId !== null) {
      links.push({
        source: node.sourceId,
        target: node.id,
      });
    }
  });

  const firstNode = nodes[0];

  if (!firstNode) return [];

  const simulation = forceSimulation<Node, LinkType>(
    nodes.map((node) => ({ ...node })),
  ) // Map nodes because forceSimulation is destructive
    .force('collide', forceCollide().radius(0.1)) // Prevent labels from overlapping
    .force('link', forceLink(links).distance(0.1)) // Establish distance between links
    .force('x', forceX(firstNode.x)) // Pull toward subject's x coordinate, helps keep labels in view
    .force('y', forceY(firstNode.y)) // Pull toward subject's y coordinate, helps keep labels in view
    .stop();

  // Manually increment simulation
  // See https://github.com/d3/d3-force/blob/master/README.md#simulation_tick
  for (
    let i = 0,
      n = Math.ceil(
        Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()),
      );
    i < n;
    ++i
  ) {
    simulation.tick();
  }

  return (
    simulation
      ?.force<ForceLink<Node, LinkType>>('link')
      ?.links()
      .map(({ source, target }: SimulationLinkDatum<SimulationNodeDatum>) => {
        const { x, y, similarityLevel, label, highlight } = source as Node;
        const { fx, fy, sourceId } = target as Node;
        return {
          id: sourceId,
          label,
          highlight,
          similarityLevel,
          propertyPosition: [
            Number(fx) * scaleX + minX,
            Number(fy) * scaleY + minY,
          ],
          labelPosition: [x * scaleX + minX, y * scaleY + minY],
        };
      }) || []
  );
}
