import { AppraisalSubjectDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useAppraisalSubjectDocument = <
  Data extends ReportApiReports,
>() => {
  return useSingleDocumentRole<AppraisalSubjectDocument, Data>(
    DocumentRoles.AppraisalSubject,
  );
};
