import React from 'react';

import { Section, TwoColTable } from '@hcs/pdf/pdf-service';
import { formatDateShort, formatMissing } from '@hcs/utils';

import { BpoSignatureDisclosureSection } from '../../features/BpoSignatureDisclosureSection/BpoSignatureDisclosureSection';
import { useReport } from '../../hooks';

import { BpoReportPage } from './BpoReportPage';

export const SignaturePage = () => {
  const report = useReport();
  const email = formatMissing(report?.realtorDetails?.email);
  const fullName = formatMissing(report?.realtorDetails?.fullName);
  const licenseExpiredAt = formatMissing(
    report?.realtorDetails?.licenseExpiredAt,
  );
  const licenseNumber = formatMissing(report?.realtorDetails?.licenseNumber);
  const licenseState = formatMissing(report?.realtorDetails?.licenseState);
  const signatureUrl = report?.realtorDetails?.signatureUrl || '';
  const signedAt = formatDateShort(report?.realtorDetails?.signedAt);
  return (
    <BpoReportPage dataHcName="signature-page">
      <Section
        sectionHeaderProps={{
          title: 'Signature & Realtor Details',
        }}
        dataHcName="signature-page-section"
      >
        <TwoColTable
          dataHcName="signature-page-table"
          smallLabels
          data={[
            {
              label: 'Broker/Agent Name',
              value: fullName,
              dataHcName: 'broker-agent-name',
            },
            {
              label: 'License State',
              value: licenseState,
              dataHcName: 'license-state',
            },
            {
              label: 'License Number',
              value: licenseNumber,
              dataHcName: 'license-number',
            },
            {
              label: 'License Expiration',
              value: licenseExpiredAt,
              dataHcName: 'license-expiration',
            },
            {
              label: 'Email',
              value: email,
              dataHcName: 'email',
            },
            {
              label: 'Signature & Date',
              value: (
                <>
                  <div>{signedAt}</div>
                  {signatureUrl ? (
                    <div>
                      <img
                        style={{
                          maxHeight: '75px',
                          paddingTop: '10px',
                        }}
                        src={signatureUrl}
                        alt="signature"
                      />
                    </div>
                  ) : null}
                </>
              ),
              dataHcName: 'signature-date',
            },
          ]}
        />
        <BpoSignatureDisclosureSection />
      </Section>
    </BpoReportPage>
  );
};
