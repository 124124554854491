import { buildStaticStreetViewUrlForPdf } from '@hcs/street-view';
import { CompId, DocumentRoles } from '@hcs/types';
import { CompIdToPhotoUrlMapping } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useCompPhotosDocuments } from './useCompPhotosDocuments';
import { useHcSuggestedCompPhotosDocuments } from './useHcSuggestedCompPhotosDocuments';
import { useSelectedOrSuggestedComps } from './useSelectedOrSuggestedComps';

export const useSelectedOrSuggestedCompFrontPhotos =
  (): CompIdToPhotoUrlMapping => {
    const hcSuggestedCompsDocs = useHcSuggestedCompPhotosDocuments();
    const compPhotosDocs = useCompPhotosDocuments();
    const { comps } = useSelectedOrSuggestedComps();
    const results: CompIdToPhotoUrlMapping = {};
    (compPhotosDocs.length ? compPhotosDocs : hcSuggestedCompsDocs).forEach(
      (doc) => {
        const compId = doc.documentId
          .replace(`${DocumentRoles.HcSuggestedCompPhotos}-`, '')
          .replace(`${DocumentRoles.CompPhotos}-`, '') as CompId;
        const comp = comps.find((c) => c.compSchema.compID === compId);
        const compGeoLocation = locationToGeoLocation(
          comp?.compSchema.propertyState.location,
        );
        const mlsPhoto = doc.data.listingPhotos?.[0]?.media?.images?.[0]?.url;
        const frontPhoto = mlsPhoto
          ? mlsPhoto
          : compGeoLocation
            ? buildStaticStreetViewUrlForPdf(compGeoLocation)
            : undefined;

        if (compId && frontPhoto) {
          results[compId] = frontPhoto;
        }
      },
    );
    return results;
  };
