import React, { ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { useIsOverflowed } from '@hcs/hooks';

import styles from './Addendum.module.css';

const LINES_CLASSNAME_MAPPING = {
  1: styles.one,
  2: styles.two,
  3: styles.three,
  4: styles.four,
  5: styles.five,
  6: styles.six,
};
export interface AddendumProps {
  /**
   * Optional className applied to main HTML element.
   */
  dataHcName?: string;
  className?: string;
  children: ReactNode;
  lines?: keyof typeof LINES_CLASSNAME_MAPPING;
  handleOverflow?: () => void;
}
export const Addendum = ({
  className,
  children,
  dataHcName,
  lines = 4,
  handleOverflow,
}: AddendumProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflowed = useIsOverflowed(ref);

  useEffect(() => {
    if (isOverflowed && handleOverflow) handleOverflow();
  }, [isOverflowed]);

  const renderedContent = (
    <div
      ref={ref}
      data-hc-name={dataHcName}
      className={classNames(
        styles.Addendum,
        className,
        LINES_CLASSNAME_MAPPING[lines],
      )}
    >
      {children}
    </div>
  );
  return isOverflowed ? (
    <div className={styles.ContentContainer}>
      <div>{renderedContent}</div>
      <div className={styles.SeeAddendum}>See Addendum</div>
    </div>
  ) : (
    renderedContent
  );
};
