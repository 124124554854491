import React from 'react';
import classNames from 'classnames';

import { ImgFit } from '@hcs/pdf/pdf-service';
import { PropertyDataSourcesTable } from '@hcs/pdf/pdf-service';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import {
  CompFields,
  PropertyStateFields,
  PropertyStateSources,
  PropertyStateType,
} from '@hcs/types';
import { AppraisalCompDocument } from '@hcs/types';
import {
  formatCityStateZip,
  formatMissing,
  formatStreetAddress,
} from '@hcs/utils';
import { capitalizeFirstLetter } from '@hcs/utils';

import { APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS } from '../..//constants/appraisalReview.constants';
import { AppraisalReviewCompVerificationFactors } from '../..//features/AppraisalReviewCompVerification/AppraisalReviewCompVerificationFactors';
import { useAppraisalComparisonDocument } from '../..//hooks/useAppraisalComparisonDocument';
import { useAppraisalCompDataSourcesDocuments } from '../..//hooks/useAppraisalCompDataSourcesDocuments';

import styles from './AppraisalReviewCompVerification.module.css';

const { Display: DisplayDistance } = COMP_FIELDS_CONFIG[CompFields.distance];
interface Props {
  comp: AppraisalCompDocument;
  photo?: string | null;
}
const dataHcName = 'apprailal-review-comp-verification';
export const AppraisalReviewCompVerification = ({ comp, photo }: Props) => {
  const biasRiskDoc = useAppraisalComparisonDocument();
  const dataSourcesDocs = useAppraisalCompDataSourcesDocuments();
  const compArg = {
    propertyStateType: PropertyStateType.Core,
    propertyState: comp.data.propertyState,
  };
  const dataSourceDoc = dataSourcesDocs.find(
    (d) => d.data.hc.hcAddressId === comp.data.propertyState.hcAddressId,
  );
  const compDataVerification =
    biasRiskDoc.data.comparables.propertyRisk[comp.data.compID]
      ?.dataVerification;
  return (
    <div
      data-hc-name={dataHcName}
      className={styles.AppraisalReviewCompVerification}
    >
      <div className={styles.Header}>
        {photo && (
          <ImgFit
            className={styles.Thumbnail}
            dataHcName={`${dataHcName}-thumbnail`}
            objectFit="cover"
            height={100}
            width={140}
            src={photo}
          />
        )}
        <div className={styles.Cell}>
          <div>
            <div className={styles.StreetAddress}>
              {formatStreetAddress(comp.data.propertyState.location)}
            </div>
            <div className={styles.CityStateZip}>
              {formatCityStateZip(comp.data.propertyState.location)}
            </div>
          </div>
          <div className={styles.CompFields}>
            <div>
              <span className={styles.FieldLabel}>
                {formatMissing(comp.data.similarity.scoreAdjusted)}%
              </span>{' '}
              Similar to Subject
            </div>
            <div>
              <span className={styles.FieldLabel}>
                {capitalizeFirstLetter(
                  formatMissing(comp.data.similarity.levelAdjusted || ''),
                )}
              </span>{' '}
              Similarity
            </div>
            <div>
              <span className={styles.FieldLabel}>
                <DisplayDistance comp={comp.data} />
              </span>{' '}
              from Subject
            </div>
          </div>
        </div>
        <div className={classNames(styles.Cell, styles.Scorecard)}>
          <AppraisalReviewCompVerificationFactors comp={comp} />
        </div>
      </div>
      <PropertyDataSourcesTable
        dataHcName={`${dataHcName}-compare-table`}
        fields={[
          PropertyStateFields.currentStatus,
          PropertyStateFields.currentPrice,
          ...APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS,
        ]}
        appraisalPropertyStateArgs={compArg}
        propertyStateSources={{
          [PropertyStateSources.HC]: dataSourceDoc?.data.hc,
          [PropertyStateSources.MLS]: dataSourceDoc?.data.mls,
          [PropertyStateSources.PR]: dataSourceDoc?.data.pr,
        }}
        dataVerificationConfig={
          compDataVerification && {
            primarySource: 'appraisal',
            compareSource: PropertyStateSources.HC,
            dataVerification: compDataVerification,
          }
        }
      />
    </div>
  );
};
