import React, { Fragment } from 'react';
import classNames from 'classnames';

import { Disclosure } from '@hcs/design-system';
import { Page, PageHeader, Title, usePdfData } from '@hcs/pdf/pdf-service';
import {
  Capability,
  DexpEndpointTier,
  DexpEndpointTierEntitlements,
  ProductSheetDexpData,
} from '@hcs/types';
import {
  arrayToChunks,
  capitalizeFirstLetter,
  formatDateLong,
} from '@hcs/utils';

import styles from './ProductSheetDexpPdf.module.css';

const EndpointsCell = ({
  endpointsChunk,
  endOfCat,
  category,
  categoryChunkIdx,
}: {
  category: string;
  endpointsChunk: Capability[];
  endOfCat: boolean;
  categoryChunkIdx: number;
}) => {
  return (
    <div
      className={classNames(styles.Category, {
        [styles.endOfCat]: endOfCat,
      })}
    >
      <h3 className={styles.CategoryTitle}>
        {categoryChunkIdx === 0 ? capitalizeFirstLetter(category) : <>&nbsp;</>}
      </h3>
      <ul className={styles.EndpointList}>
        {endpointsChunk.map((endpoint) => {
          return <li key={endpoint.key}>{endpoint.key}</li>;
        })}
      </ul>
    </div>
  );
};

const DexpTiers = ({ tiers }: { tiers: DexpEndpointTier[] }) => {
  return tiers.map(({ categories, tier }) => {
    let gridCellIndex = 0;
    return (
      <section key={tier} className={styles.Tier}>
        <h2 className={styles.TierName}>
          {tier}
          {tier !== 'Basic Data Points' && <sup>*</sup>}
        </h2>
        <div className={styles.Categories}>
          {categories.map(({ category, endpoints }, i) => {
            const endpointChunks = arrayToChunks(endpoints, 10);
            const catWillSplit =
              Math.floor(gridCellIndex / 4) !==
              Math.floor((gridCellIndex + endpointChunks.length - 1) / 4);
            return (
              <Fragment key={`${category}-${i}`}>
                {endpointChunks.map((endpointsChunk, j) => {
                  const endOfCat = j === endpointChunks.length - 1;
                  const content = (
                    <Fragment key={tier + category + j}>
                      {
                        /** render empty cells so a category is on the same line */
                        catWillSplit && j === 0 && <div>&nbsp;</div>
                      }
                      <EndpointsCell
                        endpointsChunk={endpointsChunk}
                        category={category}
                        categoryChunkIdx={j}
                        endOfCat={endOfCat}
                      />
                    </Fragment>
                  );
                  gridCellIndex++;
                  return content;
                })}
              </Fragment>
            );
          })}
        </div>
      </section>
    );
  });
};
const Header = () => (
  <PageHeader className={styles.Header} headerDetails={[]} />
);
const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={classNames(styles.FooterRow, styles.FooterTopRow)}>
        <div className={styles.Copyright}>
          © {new Date().getFullYear()} HouseCanary, All rights reserved.
        </div>
        <div>sales@housecanary.com</div>
      </div>
      <div>
        <div className={classNames(styles.Disclosure, styles.FooterRow)}>
          <b>
            *Premium and Premium Plus data points available at an additional
            fee.
          </b>
          <b>Data Points as of {formatDateLong(new Date())}</b>
        </div>
        <Disclosure
          dataHcName="disclosure"
          className={styles.Disclosure}
          horzLinksSection
        />
      </div>
    </div>
  );
};
// The default PDF gets a lazy-import in the index file
export const ProductSheetDexpPdf = () => {
  const { endpointTiers } = usePdfData<ProductSheetDexpData>();
  return (
    <>
      <Page dataHcName="dexp-page" header={<Header />} footer={<Footer />}>
        <Title description="Available Data Points" withLogoMark={false}>
          Data Explorer
        </Title>
        <div className={styles.Content}>
          <div className={styles.SheetDescription}>
            HouseCanary’s Data Explorer and Analytics API products are built to
            be seamlessly integrated into your existing processes, and a number
            of flexible delivery formats are available to cover a variety of use
            cases and workflows.
          </div>
          <DexpTiers
            tiers={[endpointTiers[DexpEndpointTierEntitlements.Basic]]}
          />
        </div>
      </Page>
      <Page dataHcName="dexp-page" header={<Header />} footer={<Footer />}>
        <div className={styles.Content}>
          <DexpTiers
            tiers={[
              endpointTiers[DexpEndpointTierEntitlements.Premium],
              endpointTiers[DexpEndpointTierEntitlements.PremiumPlus],
            ]}
          />
        </div>
      </Page>
    </>
  );
};
