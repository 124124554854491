import React from 'react';

import { NullState, Section, TwoColSection } from '@hcs/pdf/pdf-service';
import { getPredictionGroupPhoto } from '@hcs/property-state';
import {
  ActiveTop4CompPhotosDocument,
  ClosedTop4CompPhotosDocument,
  PredictionGroupType,
  SubjectPhotosDocument,
} from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import styles from './PhotosSection.module.css';

export const PhotosSection = ({
  subjectPhotos,
  title,
}: {
  subjectPhotos:
    | SubjectPhotosDocument
    | ActiveTop4CompPhotosDocument
    | ClosedTop4CompPhotosDocument
    | undefined;
  title: string;
}) => {
  const housePhotoUrl = getPredictionGroupPhoto(
    PredictionGroupType.House,
    subjectPhotos,
  );
  const kitchenPhotoUrl = getPredictionGroupPhoto(
    PredictionGroupType.Kitchen,
    subjectPhotos,
  );
  const roomPhotoUrl = getPredictionGroupPhoto(
    PredictionGroupType.Room,
    subjectPhotos,
  );
  const bathroomPhotoUrl = getPredictionGroupPhoto(
    PredictionGroupType.Bathroom,
    subjectPhotos,
  );
  const listingDetails = {
    mlsName: '',
    listingId: '',
    listingDate: '',
  };

  if (!subjectPhotos?.data?.listingPhotos) {
    return (
      <Section
        sectionHeaderProps={{
          title: title,
        }}
        dataHcName="photos-section"
      >
        <NullState dataHcName={'subject-photos-page-null-state'} />
      </Section>
    );
  }

  const firstListingPhoto = subjectPhotos.data.listingPhotos[0];

  if (firstListingPhoto) {
    const { mlsName, listingDate, listingId } =
      firstListingPhoto.listingDetails;
    listingDetails.mlsName = mlsName || '';
    listingDetails.listingId = listingId || '';
    listingDetails.listingDate = listingDate || '';
  }
  const { mlsName, listingId, listingDate } = listingDetails;
  const rightContentString = `${mlsName} | Listing ID #${listingId} | Date: ${formatDateShort(
    listingDate,
  )}`;
  return (
    <>
      <TwoColSection
        sectionHeaderProps={{
          title,
          rightContent: {
            label: '',
            value: rightContentString,
          },
        }}
        dataHcName={'subject-photos-page'}
        contentLeft={
          <div className={styles.PhotoColumn}>
            {housePhotoUrl && (
              <img
                src={housePhotoUrl}
                alt="House"
                className={styles.PhotoImg}
              />
            )}
            {kitchenPhotoUrl && (
              <img
                src={kitchenPhotoUrl}
                alt="House"
                className={styles.PhotoImg}
              />
            )}
          </div>
        }
        contentRight={
          <div className={styles.PhotoColumn}>
            {roomPhotoUrl && (
              <img src={roomPhotoUrl} alt="Room" className={styles.PhotoImg} />
            )}
            {bathroomPhotoUrl && (
              <img
                src={bathroomPhotoUrl}
                alt="Room"
                className={styles.PhotoImg}
              />
            )}
          </div>
        }
      />
      <p className={styles.MoreImages}>
        Only a few images from the MLS are displayed to give an assessment of
        the subject. There may be more images available on the MLS.
      </p>
    </>
  );
};
