import {
  DocumentRoles,
  RentalSubjectValueDocument,
  SubjectValueDocument,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSubjectValueDocument = <Data extends ReportApiReports>(
  showRentalValue?: boolean,
) => {
  const subjectValueDoc = useSingleDocumentRole<
    SubjectValueDocument | RentalSubjectValueDocument,
    Data
  >(
    showRentalValue
      ? DocumentRoles.RentalSubjectValue
      : DocumentRoles.SubjectValue,
  );
  return subjectValueDoc;
};
