import { DocumentRoles, SubjectTransactionHistoryDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSubjectTransactionHistoryDocument = <
  Data extends ReportApiReports,
>() => {
  return useSingleDocumentRole<SubjectTransactionHistoryDocument, Data>(
    DocumentRoles.SubjectTransactionHistory,
  );
};
