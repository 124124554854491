import React from 'react';
import { VictoryLabel } from 'victory';

import { BarChart } from '@hcs/pdf/charts';
import {
  barChartAxisLabelText,
  barChartDomainY,
  barChartHighlightedTick,
  barChartTickValuesX,
} from '@hcs/pdf/charts';
import { NullState } from '@hcs/pdf/pdf-service';
import { ChartSizes } from '@hcs/types';

import { useNearbyPropertiesChartDocument } from '../../hooks/useNearbyPropertiesChartDocument';

import { NEARBY_PROPERTIES_CHART_LABEL_Y_AXIS } from './NearbyPropertiesCharts.constants';

type NearbyChartKey =
  | 'sqFt'
  | 'pricePerSqFt'
  | 'siteArea'
  | 'priceSiteArea'
  | 'age'
  | 'beds'
  | 'baths';
interface Props {
  label: string;
  xAxisLabel: string;
  nearbyChartKey: NearbyChartKey;
  chartSize?: ChartSizes;
}

export const NearbyPropertiesChart = ({
  label,
  xAxisLabel,
  nearbyChartKey,
  chartSize = 'small',
}: Props) => {
  const dataHcName = `nearby-properties-chart-${nearbyChartKey}`;
  const nearbyPropertiesChartDoc = useNearbyPropertiesChartDocument();
  const chart = nearbyPropertiesChartDoc?.data.charts.find(
    (chart) => chart.id === nearbyChartKey,
  );
  if (!chart) {
    return <NullState dataHcName={`${dataHcName}-null-state`} />;
  }
  return (
    <BarChart
      dataHcName={dataHcName}
      title={label}
      chartSize={chartSize}
      xAxis={{
        label: xAxisLabel,
        tickValues: barChartTickValuesX(chart.buckets),
        tickLabelComponent: (
          <VictoryLabel text={barChartAxisLabelText(chart)} lineHeight={1.2} />
        ),
      }}
      yAxis={{
        label: NEARBY_PROPERTIES_CHART_LABEL_Y_AXIS,
        domain: barChartDomainY(chart.buckets),
      }}
      highlightedTick={barChartHighlightedTick(chart)}
      chartData={chart.buckets}
    />
  );
};
