import React from 'react';
import classNames from 'classnames';

import { ChartTitle } from '@hcs/pdf/charts';
import { Stats } from '@hcs/pdf/pdf-service';
import { AxisProps, SimilarPropertiesChartDisplay } from '@hcs/report-api';
import { ChartType } from '@hcs/types';
import { formatMoney } from '@hcs/utils';

import { useAppraisalSubjectValueDocument } from '../..//hooks/useAppraisalSubjectValueDocument';
import { useReportValue } from '../..//hooks/useReportValue';
import { useSimilarPropertiesChartDocument } from '../..//hooks/useSimilarPropertiesChartDocument';

import styles from './SimilarPropertiesChart.module.css';

interface Props {
  className?: string;
  axisLeft?: AxisProps;
  axisBottom?: AxisProps;
}
const dataHcName = 'similar-properties-chart';
export const SimilarPropertiesChart = ({
  className,
  axisBottom,
  axisLeft,
}: Props) => {
  const reportValue = useReportValue();
  const similarPropertiesChartDoc = useSimilarPropertiesChartDocument();
  const appraisalSubjectValueDoc = useAppraisalSubjectValueDocument();
  return (
    <div className={styles.SimilarPropertiesChart}>
      <ChartTitle
        dataHcName={`${dataHcName}-chart-title`}
        className={styles.ChartTitle}
      >
        Comparable Home Price Trend
      </ChartTitle>
      <SimilarPropertiesChartDisplay
        className={classNames(styles.Chart, className)}
        chartType={ChartType.Valuation}
        chart={similarPropertiesChartDoc?.data.chart}
        subjectValue={reportValue?.selectedValue?.value.value || 0}
        appraisalValue={appraisalSubjectValueDoc?.data.userValue.value.value}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
      />
      {similarPropertiesChartDoc?.data.chart && (
        <Stats
          dataHcName={`${dataHcName}-stats`}
          className={styles.Stats}
          stats={[
            {
              dataHcName: `${dataHcName}-90-lower`,
              label: '90th Percentile Lower',
              value: formatMoney(
                similarPropertiesChartDoc?.data.chart?.stats
                  .similarPropertiesStats.salePricePercentile90.lower,
              ),
            },
            {
              dataHcName: `${dataHcName}-90-upper`,
              label: '90th Percentile Upper',
              value: formatMoney(
                similarPropertiesChartDoc?.data.chart?.stats
                  .similarPropertiesStats.salePricePercentile90.upper,
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
