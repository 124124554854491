import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { SeeAddendum } from '../SeeAddendum';

import styles from './TwoColTable.module.css';

export interface TwoColTableDataItem {
  label: ReactNode;
  value: ReactNode;
  isSubLabel?: boolean;
  lineClamp?: number;
  seeAddendum?: boolean;
  skipItem?: boolean;
  borderBottom?: boolean;
  classNameValue?: string;
  dataHcName?: string;
}

export interface TwoColTableProps {
  dataHcName?: string;
  data: TwoColTableDataItem[];
  className?: string;
  enableAddendum?: boolean;
  smallLabels?: boolean;
  compact?: boolean;
  theme?: {
    Label?: string;
    Value?: string;
  };
}

const getCellHeight = (lineClamp?: number) =>
  lineClamp && lineClamp > 1 ? lineClamp * 16.18 + 3 : void 0;

export const TwoColTable = ({
  data,
  dataHcName,
  enableAddendum,
  className,
  smallLabels,
  compact,
  theme,
}: TwoColTableProps) => {
  return (
    <div data-hc-name={dataHcName} className={styles.Table}>
      {data?.map((item, idx) => {
        const dataHcNameItem = item.dataHcName
          ? `${dataHcName}-${item.dataHcName}`
          : `${dataHcName}-item`;
        return (
          <div
            data-hc-name={dataHcNameItem}
            key={`${idx}-${dataHcNameItem}`}
            className={classNames(styles.Row, className, {
              [styles.smallLabels]: smallLabels,
              [styles.compact]: compact,
              [styles.subLabelRow]: item.isSubLabel,
            })}
          >
            <span
              data-hc-name={`${dataHcNameItem}-label`}
              className={classNames(
                item.isSubLabel ? styles.SubLabel : styles.Label,
                theme?.Label,
              )}
              style={{
                height: getCellHeight(item.lineClamp),
                borderBottom: item.borderBottom === false ? 'none' : undefined,
              }}
            >
              {item.label}
            </span>
            <span
              data-hc-name={`${dataHcNameItem}-value`}
              className={classNames(
                styles.Value,
                theme?.Value,
                item.classNameValue,
              )}
              style={{
                height: getCellHeight(item.lineClamp),
                borderBottom: item.borderBottom === false ? 'none' : undefined,
              }}
            >
              <span
                className={styles.ValueItem}
                style={{ WebkitLineClamp: item.lineClamp }}
              >
                {item.value}
              </span>
              {enableAddendum && (
                <SeeAddendum
                  dataHcName={`${dataHcNameItem}-addendum`}
                  visible={item.seeAddendum}
                  isAbsolute={false}
                />
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
};
