import { ActiveTop4CompDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useActiveTop4CompDocuments = <Data extends ReportApiReports>() => {
  return useDocumentRole<ActiveTop4CompDocument, Data>(
    DocumentRoles.ActiveTop4Comp,
  );
};
