import React from 'react';
import classNames from 'classnames';

import { HomePriceIcon } from '@hcs/design-system';
import { formatMoney } from '@hcs/utils';

import { useReportValue } from '../../hooks/useReportValue';

import styles from './BrokerPriceOpinion.module.css';

interface Props {
  horizontal?: boolean;
}

export const BrokerPriceOpinion = ({ horizontal }: Props) => {
  const reportValue = useReportValue();
  return (
    <div
      className={classNames(styles.BrokerPriceOpinion, {
        [styles.Horizontal]: horizontal,
      })}
    >
      <div className={styles.InnerContainerTop}>
        <HomePriceIcon size="lg" color="success-10" />
        <div className={styles.Title}>Broker Price Opinion</div>
      </div>
      <div className={styles.InnerContainerBottom}>
        <div className={styles.PriceOpinion}>
          {formatMoney(reportValue?.selectedValue?.value.value)}
        </div>
        <div className={styles.PricePerSqFt}>
          {formatMoney(
            reportValue?.selectedValue?.calculatedFields.valuePerSqFt,
          )}{' '}
          / Sq.Ft.
        </div>
      </div>
    </div>
  );
};
