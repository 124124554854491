import React from 'react';

import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { arrayToChunks } from '@hcs/utils';

import { MAX_COMPS_PER_PAGE_COMPARE } from '../../features/CompsCompareTable/CompsCompareTable';
import { useSelectedOrSuggestedComps } from '../../hooks/useSelectedOrSuggestedComps';

import {
  PexpCompsComparePage,
  PexpCompsComparePageProps,
} from './PexpCompsComparePage';
import {
  MAX_COMPS_PER_PAGE as MAX_COMPS_PER_PAGE_MAP,
  PexpCompsMapPage,
} from './PexpCompsMapPage';

interface Props {
  compsCompareFields?: PexpCompsComparePageProps['fields'];
  compsValueFields?: (PropertyStateFields | CompFields)[];
}
export const PexpCompsPages = ({
  compsCompareFields,
  compsValueFields,
}: Props) => {
  const { comps } = useSelectedOrSuggestedComps(CompTypes.Sold);
  if (!comps.length) return null;
  const numPagesCompare = arrayToChunks(comps, MAX_COMPS_PER_PAGE_COMPARE);
  const numPagesMap = arrayToChunks(comps, MAX_COMPS_PER_PAGE_MAP);
  const numPagesTotal = numPagesCompare.length + numPagesMap.length;
  return (
    <>
      <PexpCompsMapPage
        numPagesTotal={numPagesTotal}
        compType={CompTypes.Sold}
      />
      <PexpCompsComparePage
        pageIndexInit={numPagesMap.length}
        numPagesTotal={numPagesTotal}
        fields={compsCompareFields}
        compsValueFields={compsValueFields}
      />
    </>
  );
};
