import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { DesignSystemStyles } from '@hcs/design-system';
import { experienceFlagsSlice } from '@hcs/experience-flags';
import { hcMapSlice } from '@hcs/maps';
import { MlsListingSheetPdf } from '@hcs/pdf/listing';
import {
  addendumSlice,
  PdfTemplateRendererPdf,
  usePdfState,
} from '@hcs/pdf/pdf-service';
import { productSheetsTemplateConfig } from '@hcs/pdf/product-sheets';
import { reportApiTemplateConfig } from '@hcs/pdf/report-api';
import { PdfTemplate, PdfTemplateIds } from '@hcs/types';
import { createHcReduxStore } from '@hcs/utils';
import { initSentry, ROOT_QUERY_SELECTOR } from '@hcs/webapps';

initSentry();

export const PDF_TEMPLATES_BY_ID: Partial<Record<PdfTemplateIds, PdfTemplate>> =
  {
    [PdfTemplateIds.MlsListingSheet]: MlsListingSheetPdf,
    [PdfTemplateIds.MlsListingSheetOld]: MlsListingSheetPdf,
    ...reportApiTemplateConfig,
    ...productSheetsTemplateConfig,
  };

const reduxStore = createHcReduxStore([
  hcMapSlice,
  experienceFlagsSlice,
  addendumSlice,
]);

const RenderPdf = () => {
  const { pdfTemplateId } = usePdfState();
  if (pdfTemplateId) {
    const PdfTemplate = PDF_TEMPLATES_BY_ID[pdfTemplateId];
    if (PdfTemplate) {
      return <PdfTemplateRendererPdf PdfTemplate={PdfTemplate} />;
    }
  }
  return (
    <div>
      Release: <span>{import.meta.env.NX_BUILD}</span>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.querySelector(ROOT_QUERY_SELECTOR) as Element,
);
root.render(
  <StrictMode>
    <Provider store={reduxStore}>
      <DesignSystemStyles />
      <RenderPdf />
    </Provider>
  </StrictMode>,
);
