import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import { CompFields, PropertyStateFields } from '@hcs/types';

// Type predicate for mixing PropertyStateFields and CompFields configs
export const isCompField = (
  field: PropertyStateFields | CompFields,
): field is CompFields => field in CompFields;

// Use above type predicate to get the field config in a type-safe way
export const getCompFieldConfig = (field: PropertyStateFields | CompFields) => {
  if (isCompField(field)) {
    return COMP_FIELDS_CONFIG[field];
  } else {
    return PROPERTY_STATE_FIELD_CONFIGS[field];
  }
};
