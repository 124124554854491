import {
  DocumentRoles,
  InspectionDocument,
  ReportApiReports,
} from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useInspectionDocument = <Data extends ReportApiReports>() => {
  return useSingleDocumentRole<InspectionDocument, Data>(
    DocumentRoles.Inspection,
  );
};
