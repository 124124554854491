import React, { ReactNode, Suspense } from 'react';

import { useComponentDidMount } from '@hcs/hooks';
import { PdfTemplate } from '@hcs/types';

import { usePdfState } from '../../hooks';

const TemplateReadyCallback = ({
  onTemplateReady,
}: {
  onTemplateReady: VoidFunction;
}) => {
  useComponentDidMount(() => {
    const readyTimeout = window.setTimeout(onTemplateReady, 10);
    return () => {
      if (readyTimeout) {
        clearTimeout(readyTimeout);
      }
    };
  });
  return null;
};
export interface PdfTemplateRendererProps {
  PdfTemplate: PdfTemplate;
  logDebugInfo?: boolean;
  onTemplateReady?: VoidFunction;
}
const dataHcName = 'pdf-template-renderer';
const Content = ({ children }: { children: ReactNode }) => {
  console.log('[pdf-template]', 'rendering content');
  return children;
};
export const PdfTemplateRenderer = ({
  logDebugInfo,
  PdfTemplate,
  onTemplateReady,
}: PdfTemplateRendererProps) => {
  const { pdfTemplateId, data } = usePdfState();
  if (logDebugInfo) {
    console.log(
      `[pdf-template] build = ${
        window.RELEASE
      }, pdfTemplateId = ${pdfTemplateId}, isComponent? ${
        typeof PdfTemplate === 'function'
      }`,
    );
    console.log('[pdf-template]', 'data = ', JSON.stringify(data));
  }
  return (
    <Suspense fallback={<span data-hc-name={`${dataHcName}-skeleton`} />}>
      <Content>
        <PdfTemplate />
        {onTemplateReady && (
          <TemplateReadyCallback onTemplateReady={onTemplateReady} />
        )}
      </Content>
    </Suspense>
  );
};
