import React from 'react';
import { InspectionParkingSchema } from 'packages/types/src/report-api/schemas/InspectionSchema.types';

import { TwoColSection, TwoColTable } from '@hcs/pdf/pdf-service';
import {
  formatBoolYesNo,
  formatList,
  formatMissing,
  formatNumber,
  NULL_VALUE,
} from '@hcs/utils';

import { InspectionChoiceField } from '../../component';
import { useInspectionDocument } from '../../hooks/useInspectionDocument';

const PROPERTY_CONDITION_DESCRIPTIONS_LOOKUP: Record<string, string> = {
  C1: 'New Construction, never lived in.',
  C2: 'Like new, no deferred maintenance noted. Improvements and site appear well maintained.',
  C3: 'Well maintained, normal wear and tear. Remodeling < 15 years ago.',
  C4: 'Adequately maintained, some cosmetic damage.',
  C5: 'Significant damage to home, work required is significant but economically feasible.',
  C6: 'Home is a candidate for demolition.',
};

const formatParkingItem = (p: InspectionParkingSchema) =>
  p.spaces ? `${p.description} (${formatNumber(p.spaces)})` : p.description;

export const InspectionPropertyDetailsSection = () => {
  const inspectionDoc = useInspectionDocument();
  const parking =
    inspectionDoc?.data.parking
      .filter((p) => !!p.spaces)
      .map(formatParkingItem) || [];

  /**
   * If there is no parking, inspection document will return {description: "None", spaces: null}
   * and we want to show the "None" value.
   */
  const parkingValues =
    parking?.length > 0
      ? parking
      : inspectionDoc?.data.parking
          .filter((p) => p.description === 'None')
          .map(formatParkingItem) || [];

  return (
    <TwoColSection
      dataHcName="inspection-property-details"
      sectionHeaderProps={{
        title: 'Property Details',
      }}
      contentLeft={
        <TwoColTable
          dataHcName="inspection-details-table"
          data={[
            {
              label: 'Evidence of Occupancy',
              value: formatMissing(inspectionDoc?.data.occupied),
            },
            {
              label: '',
              value: (
                <InspectionChoiceField
                  choices={[
                    'Cars In Driveway',
                    'Evidence Of Furniture',
                    'Window Coverings',
                    'Air Conditioner On',
                    'Smoke From Furnace',
                    'Shoveled Walkway',
                    'Garbage Cans',
                    'Lawn Maintenance',
                    'Name On Mailbox',
                    'Lights On',
                    'Other',
                  ]}
                  values={inspectionDoc?.data.occupiedEvidence || []}
                />
              ),
              isSubLabel: true,
            },
            {
              label: 'Property Viewable',
              value: formatBoolYesNo(inspectionDoc?.data.propertyViewable),
            },
            {
              label: 'Comment',
              value: formatMissing(
                inspectionDoc?.data.comment.propertyViewable,
              ),
              isSubLabel: true,
            },
            {
              label: 'Attachment Type',
              value: formatMissing(inspectionDoc?.data.attachmentType),
            },
            {
              label: 'PUD',
              value: formatMissing(inspectionDoc?.data.pud),
            },
            {
              label: 'Common Elements',
              value: (
                <InspectionChoiceField
                  choices={[
                    'Pool',
                    'Gym',
                    'Tennis Court',
                    'Basketball Court',
                    'Clubhouse',
                    'Dog Park',
                    'Golf Course',
                    'Recreation Area',
                    'Park',
                    'Other',
                    'None',
                  ]}
                  values={inspectionDoc?.data.commonElements || []}
                />
              ),
            },
            {
              label: 'Stories',
              value: formatNumber(inspectionDoc?.data.stories),
            },
            {
              label: 'Other Structures on Property',
              value: (
                <InspectionChoiceField
                  choices={[
                    'Accessory Unit',
                    'Greenhouse',
                    'Toolshed',
                    'Workshop',
                    'Barn',
                    'Pole Building',
                    'Stables',
                    'Riding Arena',
                    'Pool House',
                    'None Visible',
                  ]}
                  values={inspectionDoc?.data.otherStructuresOnProperty || []}
                />
              ),
            },
            {
              label: 'Overall Condition',
              value: formatMissing(inspectionDoc?.data.condition),
            },
            {
              label: '',
              value: inspectionDoc?.data.condition
                ? PROPERTY_CONDITION_DESCRIPTIONS_LOOKUP[
                    inspectionDoc.data.condition
                  ]
                : NULL_VALUE,
            },
            {
              label: 'Neighborhood Description',
              value: formatMissing(inspectionDoc?.data.neighborhoodDescription),
            },
            {
              label: 'Locational Influences',
              value: (
                <InspectionChoiceField
                  choices={[
                    'Residential',
                    'Industrial',
                    'Commercial',
                    'Busy Road',
                    'Water Front',
                    'Golf Course',
                    'Adjacent To Park',
                    'Overhead Power Lines',
                    'Landfill',
                    'Public Transportation',
                    'Railroad Tracks',
                    'Flight Path',
                    'Near Highway',
                    'Other',
                  ]}
                  values={[
                    inspectionDoc?.data.locationalInfluences.impact || '',
                    ...(inspectionDoc?.data.locationalInfluences.value || []),
                  ]}
                />
              ),
            },
          ]}
        />
      }
      contentRight={
        <TwoColTable
          dataHcName="inspection-details-table"
          data={[
            {
              label: 'Conform to Neighborhood',
              value: formatBoolYesNo(inspectionDoc?.data.conformToNeighborhood),
            },
            {
              label: '',
              value: formatMissing(
                inspectionDoc?.data.comment.conformToNeighborhood,
              ),
              isSubLabel: true,
            },
            {
              label: 'Non-Residential Use',
              value: formatBoolYesNo(
                inspectionDoc?.data.nonResidentialUse.value,
              ),
            },
            {
              label: '',
              value: formatList(inspectionDoc?.data.nonResidentialUse.options),
              isSubLabel: true,
            },
            {
              label: 'View Factors',
              value: formatList(inspectionDoc?.data.viewFactors.value),
            },
            {
              label: 'Impact on Value',
              value: formatMissing(inspectionDoc?.data.viewFactors.impact),
              isSubLabel: true,
            },
            {
              label: 'View Location',
              value: formatMissing(
                inspectionDoc?.data.viewFactors.viewLocation,
              ),
              isSubLabel: true,
            },
            {
              label: 'Car Parking (# spaces)',
              value: (
                <InspectionChoiceField
                  choices={
                    inspectionDoc?.data.parking.map(formatParkingItem) || []
                  }
                  values={parkingValues}
                />
              ),
            },
            {
              label: 'Special Amenities',
              value: (
                <InspectionChoiceField
                  choices={[
                    'Patio',
                    'Deck',
                    'Porch',
                    'Balcony',
                    'Fireplace',
                    'Front Yard',
                    'Back Yard',
                    'Courtyard',
                    'Solar Panel(s)',
                    'Ornate Landscaping',
                    'Irrigation System',
                    'Graywater System',
                    'Water Collection System',
                    'Outdoor Kitchen',
                    'In-ground Pool',
                    'In-ground Hot Tub',
                    'Sport Court',
                    'Dock',
                    'Sauna',
                    'RV Parking',
                    'Orchard (hobby)',
                    'Vineyard (hobby)',
                    'Other',
                    'None',
                  ]}
                  values={inspectionDoc?.data.specialAmenities || []}
                />
              ),
            },
          ]}
        />
      }
    />
  );
};
